import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaService } from 'src/media/services/media.service';
import { Journey } from 'src/models/_classes';
import { IMedia } from 'src/models/_interfaces';
import { Media } from 'src/models/_classes';
import { SignalRMediaService } from 'src/signal-r/services/signal-r-media.service';

@Component({
  selector: 'app-media-sr',
  templateUrl: './media-sr.component.html',
  styleUrls: ['./media-sr.component.scss']
})
export class MediaSrComponent implements OnInit {
  
  private readonly mediaStreamObserver;
  src: string;
  created: string | Date;
  
  @Input() journey: Journey;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private srMediaService: SignalRMediaService,
    private mediaService: MediaService
  ) { 
    this.src = '../../assets/images/sea-with-ship-horizont.png';
    
    this.mediaStreamObserver = {
      next: (md: Media) => 
      { 
        this.drawImage(md);
      }
    }
    SignalRMediaService.$mediaSrStream.subscribe(this.mediaStreamObserver);
  }

  ngOnInit(): void {
    const journeyId = this.route.snapshot.paramMap.get('journeyId');
  }

  drawImage(media: Media): void {
    
    const dateCreated: Date = new Date(media.created.toLocaleString());
    
    
    this.created = dateCreated;
    this.src = this.mediaService.concatDataUrl(media.dataUrlPrefix, media.data);
  }

}

