<ul class="navbar-nav" *ngIf="isAuthenticated | async">
    <li class="nav-item mr-2">
        <a class="nav-link text-light" (click)="isMenuCollapsed = true" [routerLink]='["/authentication/profile"]' title="Manage">Hello {{ userName | async }}</a>
    </li>
    <li class="nav-item">
        <a class="nav-link text-dark" (click)="isMenuCollapsed = true" [routerLink]='["/authentication/logout"]' [state]='{ local: true }' title="Logout">
            <i class="bi bi-box-arrow-right bi-navtop"></i>
        </a>
    </li>
</ul>
<ul class="navbar-nav" *ngIf="!(isAuthenticated | async)">
    <!--<li class="nav-item">
            <a class="nav-link text-dark" [routerLink]='["/Register"]'>Register</a>
        </li>-->
    <li class="nav-item mr-2">
        <a class="nav-link text-dark" (click)="isMenuCollapsed = true" [routerLink]='["authentication/login"]'>
            <i class="bi bi-box-arrow-in-right bi-navtop"></i>
        </a>
    </li>
</ul>