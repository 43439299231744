import { Component, OnInit } from '@angular/core';
import { JourneyService } from 'src/app/services/journey.service';
import { Journey } from 'src/models/_classes';
import { JourneyState } from 'src/models/_interfaces';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { PLDatePickerAdapter } from 'src/utilities/datepicker-adapter';
import { Router } from '@angular/router';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-journeycreator',
  templateUrl: './journeycreator.component.html',
  styleUrls: ['./journeycreator.component.scss'],
  providers: [{provide: NgbDateAdapter, useClass: PLDatePickerAdapter}]
})
export class JourneycreatorComponent implements OnInit {

  private readonly configurationPath: string = '/Configuration';

  public journey: Journey;

  public readonly journeyNameDefault = 'Musterbeschreibung - bitte ersetzen';
  public readonly journeyDescriptionDefault = 'Musterbeschreibung - bitte ersetzen';
  public readonly journeyStartDefault = 'Musterausgangspunkt - bitte ergänzen';
  public readonly journeyFinishDefault = 'Musterreiseziel - bitte ergänzen';
  public readonly journeyDateBeginDefault = '01.01.2022';

  public journeyData: FormGroup;

  constructor(
    private journeyService: JourneyService,
    private router: Router,
    private formBuilder: FormBuilder
  )
  {
    this.createForm();
  }
  ngOnInit(): void {
  }

  onSubmitCreated(): void {

    let newJourney: Journey;

    if (this.journeyData.valid) {

      newJourney = new Journey(this.jd.journeyName?.value);
      newJourney.bPersistentStorage = this.jd.journeyBPersistantStorage.value;
      newJourney.bWeatherUpdate = this.jd.journeyBWeatherUpdate.value
      newJourney.bPositonUpdate = this.jd.jounrneyBPositonUpdate.value

      newJourney.description = this.journeyDescriptionDefault;
      newJourney.start = this.journeyStartDefault;
      newJourney.finish = this.journeyFinishDefault;
      newJourney.isActive = true;
      newJourney.state = JourneyState.Created;
      newJourney.journeyGuid = Guid.raw();

      this.addJourney(newJourney);
    }
    else {
      return;
    }
  }

  addJourney(journey: Journey): void {

    this.journeyService.addJourney(journey).subscribe(
      {
        next: j => { this.journey = j; },
        complete: () => {
          this.router.navigate([this.configurationPath, this.journey.journeyId]);
          console.log('journeyservice.addJourney: complete called');
        }
      }
    );
  }

  createForm(): void {
    this.journeyData = this.formBuilder.group({
      journeyName : ['', [Validators.required, Validators.minLength(4)]],
      journeyBPersistantStorage: true,
      jounrneyBPositonUpdate: true,
      journeyBWeatherUpdate: true
    });
  }

  get jd(): { [key: string]: AbstractControl } { return this.journeyData.controls; }
}
