import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PauleoTravelGuest } from 'src/models/_classes';
import { GuestService } from '../services/guest.service';

@Component({
  selector: 'app-guest-overview',
  templateUrl: './guest-overview.component.html',
  styleUrls: ['./guest-overview.component.scss']
})
export class GuestOverviewComponent implements OnInit {

  guest: PauleoTravelGuest;
  guests: PauleoTravelGuest[];

  constructor(
    private guestService: GuestService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.guests == null) {
      this.guests = new Array<PauleoTravelGuest>();
      this.getGuests();
    }
  }

  getGuests(): void {
    this.guestService.getGuests().subscribe(
      {
        next: j => {
          this.guests = new Array<PauleoTravelGuest>();
          j.forEach(value => {
            this.guests.push(value);
          });
        },
        complete: () =>
        {
          console.log('journeyservice.getGuests: complete called'); 
        }
      }
    );
  }
}
