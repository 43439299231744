import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Journey, WeatherData } from 'src/models/_classes';
import { SignalRWeatherService } from 'src/signal-r/services/signal-r-weather.service';
import { WeatherUtilities } from 'src/signal-r/utilities/weather-utilities';
import { IWeatherData } from 'src/models/_interfaces';
import { WeahterService } from 'src/app/services/weahter.service';

@Component({
  selector: 'app-weather-sr',
  templateUrl: './weather-sr.component.html',
  styleUrls: ['./weather-sr.component.scss']
})
export class WeatherSrComponent implements OnInit {

  private readonly weahterStreamObserver;
  
  weatherData: WeatherData;
  weatherDataCollection: Array<IWeatherData>;
  weatherDataAvailable: boolean;
  weatherIcon: string
  created: string | Date;

  @Input() journey: Journey;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private srWeatherService: SignalRWeatherService,
    private weatherService: WeahterService
  ) { 
    this.weatherData = new WeatherData();
    this.weatherDataCollection = [];
    this.weatherDataAvailable = false;
    this.weatherIcon = '../../../assets/icons/weather/wi-day-sunny.svg';
    this.weahterStreamObserver = {
      next: (wd: WeatherData) => 
      { 
        const dateCreated: Date = new Date(wd.created.toLocaleString())
        this.created = dateCreated;

        this.weatherData = wd;
        this.weatherDataCollection.push(wd);
        this.weatherDataAvailable = true;
        this.weatherIcon = this.getWeatherIcon(wd.weatherId);
      }
    }
    SignalRWeatherService.weatherRStream.subscribe(this.weahterStreamObserver);
  }

  ngOnInit(): void {
  }

  getWeatherIcon(weatherId: number): string {
    return WeatherUtilities.getIconPath(weatherId);
  }

}
