const hubEndpoints: HubType = {

    MediaHub: `/hubs/mediahub`,
    JourneyHub: `/hubs/journeyhub`,
    WeatherHub: `/hubs/weatherhub`,
  };

interface HubType {

    readonly MediaHub: string;
    readonly JourneyHub: string;
    readonly WeatherHub: string;
}

export const HubPaths: HubType = hubEndpoints;