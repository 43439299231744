import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from 'src/alert/components/alert/alert.component';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastContainerComponent } from 'src/alert/components/toast-container/toast-container.component';


@NgModule({
  declarations: [
    AlertComponent,
    ToastContainerComponent
  ],
  imports: [
    CommonModule,
    NgbToastModule
  ],
  exports: [
    AlertComponent,
    ToastContainerComponent
  ]
})
export class AlertModule { }
