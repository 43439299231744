import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { UserMedia } from 'src/media/classes/user-media';
import { ActivatedRoute } from '@angular/router';
import { JourneyService } from 'src/app/services/journey.service'
import { Journey } from 'src/models/_classes';
import { MediaService } from 'src/media/services/media.service';
import { DeviceEventSeviceService } from 'src/app/services/device-event-sevice.service';
import { IDeviceOrientation } from 'src/models/_interfaces';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit {

  @ViewChild('video') videoElement: ElementRef;
  @ViewChild('img') imageElement: ElementRef;
  @ViewChild('canvas') canvasElement: ElementRef;

  private readonly userMedia: UserMedia;
  private video: HTMLVideoElement;
  private img: HTMLImageElement;
  private canvas: HTMLCanvasElement;
  private dataUrl: string;
  private deviceOrientation: IDeviceOrientation
  private mediaStreamConstraints: MediaStreamConstraints

  journey: Journey;
  bStreaming: boolean;
  bPictureInProcess: boolean;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private journeyService: JourneyService,
    private mediaService: MediaService,
    private deviceEventService: DeviceEventSeviceService
  ) {
    this.userMedia = new UserMedia();
    // setup standard media stream constraints
    this.mediaStreamConstraints = {
      video: {
        facingMode: 'environment',
        width: {
          min: 400,
          ideal: 800,
          max: 1050
        },
        height: {
          min: 225,
          ideal: 450,
          max: 590
        },
        aspectRatio: 16/9
      },
      audio: false
    }
  }

  ngOnInit(
    ): void {
      const journeyId = this.route.snapshot.paramMap.get('journeyId');
      this.getJourney(parseInt(journeyId, 10));
    }

  getJourney(jounreyId: number): void {
    this.journeyService.getJourney(jounreyId).subscribe(
      {
        next: j => {
          this.journey = j;
        },
        complete: () => { 
          this.bStreaming = true;
          this.playMediaStream();
        }
      }
    );
  }

  handleDeviceOrientation(o: IDeviceOrientation): void {
    
    this.deviceOrientation.alpha = o.alpha || 0;
    this.mediaStreamConstraints.video['aspectRatio'] = 9/16;

    /*if (this.deviceOrientation.alpha <= 45 || this.deviceOrientation.alpha >= -46) {
      this.mediaStreamConstraints.video['aspectRatio'] = 16/9;
    }
    else {
      this.mediaStreamConstraints.video['aspectRatio'] = 9/16;
    }*/
  }

  playMediaStream(): void {

    this.userMedia.getUserMedia(this.mediaStreamConstraints).subscribe(
      (mediaStream) => {
        this.bStreaming = true;
        this.video = this.videoElement.nativeElement;
        this.video.srcObject = mediaStream;
        this.video.play();
      }
    );
  }

  stopMediaStream(): void {
    this.bStreaming = false;

    const mediaStream = this.video.srcObject;

    const tracks = (mediaStream as MediaStream).getTracks();
    tracks.forEach((track) => {
      track.stop();
    });
  }

  takePicture(): void {

    this.bPictureInProcess = true;
    
    this.canvas = this.canvasElement.nativeElement;
    this.img = this.imageElement.nativeElement;

    const context: CanvasRenderingContext2D = this.canvas.getContext('2d');

    this.canvas.style.width = '100%';
    this.canvas.width = this.canvas.offsetWidth;
    this.canvas.height = (this.canvas.width * this.mediaStreamConstraints.video['aspectRatio']);

    context.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);

    this.processPicture(this.canvas.width, this.canvas.height);

    this.dataUrl = this.canvas.toDataURL('image/png');

    this.img.setAttribute('src', this.dataUrl);
  }

  processPicture(originalWidth: number, originalHeight: number): void {
    // tbd-sz coming soon: code for rezizing, filtering, quality
    
  }

  recordPicture(): void {
    this.mediaService.addMedia(this.dataUrl, this.journey)
      .subscribe(
        {
          next: (m) => {},
          complete: () => { this.locationBack() }
          
        }
      );
  }

  takeVideo(): void {}

  clearCanvas(): void {
    const context: CanvasRenderingContext2D = this.canvas.getContext('2d');
    context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.bPictureInProcess = false;
  }

  locationBack(): void {
    this.stopMediaStream();
    this.location.back();
  }
}
