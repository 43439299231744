import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPaths, ApiUrl } from 'src/api/api.constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private readonly MessageApiPath = ApiUrl + ApiPaths.Message;
  constructor(
    private httpclient: HttpClient
  ) { }

  postMessage(journeyId: number): Observable<number> {
    return this.httpclient.post<number>(this.MessageApiPath, journeyId);
  }
}

