<div class="row">
    <div class="col-sm-12">
        <h3>Einstellungen</h3>
        <table class="table">
            <thead>

            </thead>
            <tbody>
                <tr>
                    <th scope="row">Freunde</th>
                    <td>
                        <a class="nav-link d-flex flex-column" [routerLink]="['/GuestOverview']">
                            <i class="bi bi-people-fill" style="font-size: large;"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
