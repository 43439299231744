import { IUser } from 'src/api-authorization/authorize.service'
import { 
    IJourney,
    IPositionFlat,
    IContent,
    IMedia,
    IJourneyGuest,
    IJourneyTraveler, 
    IHostToGuest,
    IWeather,
    IMessage,
    IWeatherData,
    IPosition,
    ICoordinates,
    IDeviceOrientation,
} from './_interfaces';

export class Journey implements IJourney {

    constructor(name: string) {
        this.name = name;
    }

    journeyId: number;
    name: string;
    owner: any;
    created: Date | string;
    start: string;
    finish: string;
    dateBegin: Date | string;
    description: string;
    journeyTravelers: JourneyTraveler[];
    journeyGuests: JourneyGuest[];
    locations: Location[];
    state: any; // should be of type JourneyState but for extended flexibility any
    isActive: boolean;
    bPersistentStorage: boolean;
    bPositonUpdate: boolean;
    bWeatherUpdate: boolean;
    positionUpdateInterval: number;
    weatherUpdateInterval: number;
    invitationSent: Date | string;
    journeyGuid: string;
}

export class Content implements IContent {
    id: number;
    journey: IJourney;
    created: Date | string;
    owner: IUser;
}

export class Message extends Content implements IMessage {
    body: string;
}

export class Media extends Content implements IMedia {
    fileName: string;
    fileType: string;
    dataUrlPrefix: string;
    data: any;
    journeyId: number;
    ownerId: number;
}

export class User implements IUser {
    name?: string;
}

export class IdentityUser {
    email: string;
    emailConfirmed: boolean;
    passwordHash: string;
    securityStamp: string;
    phoneNumber: string;
    phoneNumberConfirmed: boolean;
    twoFactorEnabled: boolean;
    lockoutEndDateUtc: Date | string;
    lockoutEnabled: boolean;
    lccessFailedCount: number;
    roles: any[];
    claims: any[];
    logins: any[];
    id: number;
    userName: string;
}

export class PauleoTravelUser extends IdentityUser {
    journeyTravelers: IJourneyTraveler[];
    journeyGuests: IJourneyGuest[];
    journeys: IJourney[];
    hosts: IHostToGuest[];
    guests: IHostToGuest[];
    isGuest: boolean;
}

export class PauleoTravelGuest extends PauleoTravelUser {
    info: string;
}

export class JourneyTraveler implements IJourneyTraveler {
    TravelerId: number;
    JourneyId: number;
    Traveler: IUser;
    Journey: Journey;
}

export class JourneyGuest implements IJourneyGuest {
    guestId: number;
    journeyId: number;
    guest: IUser;
    journey: Journey;
}

export class HostToGuest implements IHostToGuest {
    KnownAs: string;
    Info: string;
    GuestId: number;
    HostId: number;
    Guest: IUser;
    Host: IUser;
}

export class Weather implements IWeather {

    description: string;
    temperature: number;
    icon: string;
    weatherId: number;
    pos: IPositionFlat;
    name: string;
    journeyId: number;
    ownerId: number;
}

export class WeatherData extends Content implements IWeatherData {
    description: string;
    temperature: number;
    icon: string;
    weatherId: number;
    pos: IPositionFlat;
    name: string;
    journeyId: number;
    ownerId: number;
}

export class PositionFlat extends Content implements IPositionFlat {
    // flat copy of geolocation js api - position
    accuracy: number;
    altitude: number;
    altitudeAccuracy: number;
    heading: number;
    latitude: number;
    longitude: number;
    speed: number;
    timestamp: number;

    // pl-relatet stuff
    journeyId: number;
    ownerId: number;
}

export class Position implements IPosition {
    readonly coords: ICoordinates;
    readonly timestamp: number;
}
  
/** The position and altitude of the device on Earth, as well as the accuracy with which these properties are calculated. */
export class Coordinates implements ICoordinates {
    readonly accuracy: number;
    readonly altitude: number | null;
    readonly altitudeAccuracy: number | null;
    readonly heading: number | null;
    readonly latitude: number;
    readonly longitude: number;
    readonly speed: number | null;
}

export class DeviceOrientation  implements IDeviceOrientation {
    alpha: number;
    beta: number;
    gamma: number;
    absolute: boolean
  }