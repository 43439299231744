<div class="row">
    <div class="col-md-12">
        <h3 *ngIf="journey != null">{{ journey.name }} - f&uuml;ge deine Freunde hinzu</h3>
        <div *ngIf="journeyGuests != null">
            <form id="form-addKnownUserToGuests">
                <div class="form-group">
                    <label for="knownUsers">Deine Freunde</label>
                    <select class="form-control" [(ngModel)]="selectedKnownUser" id="knownUsers" name="selectedKnownUser">
                        <option *ngFor="let knownuser of knownusers" [ngValue]="knownuser">{{ knownuser.userName }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <div class="btn-group mr-3" role="group" aria-label="first group">
                        <button id="addKnownUserToGuests" class="btn btn-primary" [hidden]="!knownusers.length > 0" (click)="addKnownUserToJourneyGuests()">+ als Gast hinzuf&uuml;gen</button>
                        <div class="alert alert-primary" role="alert" [hidden]="knownusers.length > 0">Alle deine Freunde wurden der Reise hinzugef&uuml;gt</div>
                    </div>
                </div>
            </form>
            <hr />
            <h3>Hinzugef&uuml;gt</h3>
            <table class="table table-hover" id="guestoverview">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">EMail</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let guest of journeyGuests">
                        <td>{{ guest.id }}</td>
                        <td>{{ guest.userName }}</td>
                        <td>{{ guest.email}}</td>
                    </tr>
                </tbody>
            </table>
            <form>
                <div id="form-submitGuests" class="btn-group mr-3" role="group" aria-label="first group">
                    <button id="submitGuests" class="btn btn-primary" (click)="onSubmitUpdatedJourney()">Speichern</button>
                </div>
                <div class="btn-group mr-3" role="group" aria-label="second group">
                    <button type="button" class="btn btn-warning btn" [routerLink]="['/JourneyManager/control-panel', journey.journeyId]" outerLinkActive="router-link-active">Abbrechen</button>
                </div>
            </form>
        </div>
    </div>
</div>