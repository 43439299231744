import { Journey } from 'src/models/_classes';
import { JourneyState } from 'src/models/_interfaces';

export class JourneyStateUtilities {

    public readonly CREATED: string;
    public readonly CONFIGURED: string;
    public readonly STARTED: string;
    public readonly HIDDEN: string;
    public readonly STOPPED: string;
    public readonly FINISHED: string;
    public readonly CLOSED: string;

    // what journeystates can do
    private canConfigure: boolean;
    private canChangeState: boolean;
    private canAddGuests: boolean;
    private canInviteGuests: boolean;
    private canUpdatePosition: boolean;
    private canUpdateWeather: boolean;
    private canPublish: boolean;

    constructor() {
        this.CREATED = 'angelegt';
        this.CONFIGURED = 'konfiguriert';
        this.STARTED = 'gestartet';
        this.HIDDEN = 'verborgen';
        this.STOPPED = 'gestoppt';
        this.FINISHED = 'beendet';
        this.CLOSED = 'geschlossen';
    }

    getFriendlyJourneyState(joureyState: JourneyState | any): string {

        let friendlyJourneyState: string;

        switch (joureyState) {
            case JourneyState.Created: {
                friendlyJourneyState = this.CREATED;
                break;
            }
            case JourneyState.Configured: {
                friendlyJourneyState = this.CONFIGURED;
                break;
            }
            case JourneyState.Started: {
                friendlyJourneyState = this.STARTED;
                break;
            }
            case JourneyState.Hidden: {
                friendlyJourneyState = this.HIDDEN;
                break;
            }
            case JourneyState.Stopped: {
                friendlyJourneyState = this.STOPPED;
                break;
            }
            case JourneyState.Finished: {
                friendlyJourneyState = this.FINISHED;
                break;
            }
            case JourneyState.Closed: {
                friendlyJourneyState = this.CLOSED;
                break;
            }
            default:
                friendlyJourneyState = 'status nicht definiert - bitte prüfen';
                break;
        }
        return friendlyJourneyState;
    }

    getJourneyState(friendlyJourneyState: string | any): JourneyState {

        let journeyState: JourneyState;

        switch (friendlyJourneyState) {
            case this.CREATED: {
                journeyState = JourneyState.Created;
                break;
            }
            case this.CONFIGURED: {
                journeyState = JourneyState.Configured;
                break;
            }
            case this.STARTED: {
                journeyState = JourneyState.Started;
                break;
            }
            case this.HIDDEN: {
                journeyState = JourneyState.Hidden;
                break;
            }
            case this.STOPPED: {
                journeyState = JourneyState.Stopped;
                break;
            }
            case this.FINISHED: {
                journeyState = JourneyState.Finished;
                break;
            }
            case this.CLOSED: {
                journeyState = JourneyState.Closed;
                break;
            }
            default:
                journeyState = null;
                break;
        }
        return journeyState;
    }

    /* toggleJourneyStateTo(mode: string, value: string | JourneyState): any {

        if (value === typeof JourneyState && mode === 'friendly') {
          return this.getFriendlyJourneyState(value);
        }
        else {
          return this.getJourneyState(value);
        }
      } */

    assingJourneyStateCapabilities(journeyState: JourneyState): any {

        switch (journeyState) {
            case JourneyState.Created: {
              break;
            }
            case JourneyState.Configured: {
              break;
            }
            case JourneyState.Started: {
              break;
            }
            case JourneyState.Hidden: {
              break;
            }
            case JourneyState.Stopped: {
              break;
            }
            case JourneyState.Finished: {
              break;
            }
            case JourneyState.Closed: {
              break;
            }
            default:
              break;
          }
    }

    getClassAtrribute(journeyState: JourneyState): string {

        // styles for background-color set in style.css
        const classArributes = [
          'jCreated',
          'jConfigured',
          'jStarted',
          'jHidden',
          'jStopped',
          'jFinished',
          'jClosed'
        ];

        let classAttribute: string;

        switch (journeyState) {
          case JourneyState.Created: {
            classAttribute = classArributes[0];
            break;
          }
          case JourneyState.Configured: {
            classAttribute = classArributes[1];
            break;
          }
          case JourneyState.Started: {
            classAttribute = classArributes[2];
            break;
          }
          case JourneyState.Hidden: {
            classAttribute = classArributes[3];
            break;
          }
          case JourneyState.Stopped: {
            classAttribute = classArributes[4];
            break;
          }
          case JourneyState.Finished: {
            classAttribute = classArributes[5];
            break;
          }
          case JourneyState.Closed: {
            classAttribute = classArributes[6];
            break;
          }
          default:
            classAttribute = '';
            break;
        }
        return classAttribute;
    }

    sortByState(a: Journey, b: Journey): number {

        // hierarchy:
        // started  -> 0
        // hidden -> 1
        // stopped -> 2
        // configured -> 3
        // created -> 4
        // finished -> 5
        // closed -> 6

        let aValue: number;
        let bValue: number;

        switch (a.state) {
            case JourneyState.Created: {
                aValue = 4;
                break;
            }
            case JourneyState.Configured: {
                aValue = 3;
                break;
            }
            case JourneyState.Started: {
                aValue = 0;
                break;
            }
            case JourneyState.Hidden: {
                aValue = 1;
                break;
            }
            case JourneyState.Stopped: {
                aValue = 2;
                break;
            }
            case JourneyState.Finished: {
                aValue = 5;
                break;
            }
            case JourneyState.Closed: {
                aValue = 6;
                break;
            }
        }

        switch (b.state) {
            case JourneyState.Created: {
                bValue = 4;
                break;
            }
            case JourneyState.Configured: {
                bValue = 3;
                break;
            }
            case JourneyState.Started: {
                bValue = 0;
                break;
            }
            case JourneyState.Hidden: {
                bValue = 1;
                break;
            }
            case JourneyState.Stopped: {
                bValue = 2;
                break;
            }
            case JourneyState.Finished: {
                bValue = 5;
                break;
            }
            case JourneyState.Closed: {
                bValue = 6;
                break;
            }
        }

        if (aValue < bValue) { return -1; }
        if (aValue > bValue) { return 1; }

        return 0;
      }
}

