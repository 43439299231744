import {Injectable} from '@angular/core';
import {NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class PLDatePickerAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
        const date = (value as string).split(this.DELIMITER);
        return {
          year: parseInt(date[0], 10),
          month : parseInt(date[1], 10),
          day : parseInt(date[2].substring(0, 2), 10)
        };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {

    if (date) {

      const year: string = date.year.toString();
      const month: string = date.month < 10 ? '0' + date.month.toString() : date.month.toString();
      const day: string = date.day < 10 ? '0' + date.day.toString() : date.day.toString();

      return year + this.DELIMITER + month + this.DELIMITER + day;
    }
    else {
      return null;
    }
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}