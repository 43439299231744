import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Journey, JourneyGuest, PauleoTravelGuest, PauleoTravelUser } from 'src/models/_classes';
import { GuestService } from '../services/guest.service';
import { JourneyService } from '../services/journey.service';

@Component({
  selector: 'app-journey-add-guest',
  templateUrl: './journey-add-guest.component.html',
  styleUrls: ['./journey-add-guest.component.scss']
})
export class JourneyAddGuestComponent implements OnInit {

  journey: Journey;
  host: PauleoTravelUser;
  knownusers: PauleoTravelGuest[]; // a list of all known users related to host user
  journeyGuests: PauleoTravelGuest[]; // a list of all known users assigned to specific journey

  selectedKnownUser: PauleoTravelGuest;

  constructor(
    private guestService: GuestService,
    private journeyService: JourneyService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    const journeyId = this.route.snapshot.paramMap.get('journeyId');
    this.journeyGuests = new Array<PauleoTravelGuest>();
    this.knownusers = new Array<PauleoTravelGuest>();
    this.getJourney(parseInt(journeyId, 10));
  }

  getJourney(jounreyId: number): void {

    this.journeyService.getJourney(jounreyId).subscribe(
      {
        next: j => {
          if (j != null) {
            this.journey = j;
            this.getKnownUsers();
          }
        }
      }
    );
  }

  getKnownUsers(): void {
    this.guestService.getGuests().subscribe(
      {
        next: g => {
          g.forEach(ku => { this.knownusers.push(ku); });
        },
        complete: () => {
          this.removeJourneyGuestsFromKnownUsers();
          this.sortKnownUsersByUserName();
          this.selectedKnownUser = this.knownusers[0];
        }
      }
    );
  }

  removeJourneyGuestsFromKnownUsers(): void {

    if (this.journey?.journeyGuests != null) {

      const jg: JourneyGuest[] = this.journey.journeyGuests;
      const tmpKnownUsers: PauleoTravelGuest[] = new Array<PauleoTravelGuest>();

      this.knownusers.forEach(ku => {

        if (jg.findIndex(g => g.guestId === ku.id) > -1) {
          this.journeyGuests.push(ku);
        }
        else {
          tmpKnownUsers.push(ku);
        }
        this.knownusers = tmpKnownUsers;
      });
    }
  }

  sortKnownUsersByUserName(): void {
    this.knownusers.sort((a, b) => {
      if (a.userName < b.userName) { return -1; }
      if (a.userName > b.userName) { return 1; }
      return 0;
    });
  }

  sortGuestsByUserName(): void {
    this.journeyGuests.sort((a, b) => {
      if (a.userName < b.userName) { return -1; }
      if (a.userName > b.userName) { return 1; }
      return 0;
    });
  }

  addKnownUserToJourneyGuests(): void {
    if (this.selectedKnownUser != null) {

      const tmpKnownUsers: PauleoTravelGuest[] = new Array<PauleoTravelGuest>();
      const guest = this.selectedKnownUser;

      this.journeyGuests.push(guest);

      if (this.journey != null) {

        const journeyGuest: JourneyGuest = new JourneyGuest();
        journeyGuest.guestId = guest.id;
        journeyGuest.journeyId = this.journey.journeyId;

        if (this.journey.journeyGuests === null) {
          this.journey.journeyGuests = new Array<JourneyGuest>();
        }

        this.journey.journeyGuests.push(journeyGuest);
      }

      // remove selected known user from known users list after adding to guests
      this.knownusers.forEach(u => {
        if (u.id !== guest.id ) {
          tmpKnownUsers.push(u);
        }
      });

      this.knownusers = tmpKnownUsers;
      this.sortKnownUsersByUserName();
      this.sortGuestsByUserName();

      if (this.knownusers.length > 0) {
        this.selectedKnownUser = this.knownusers[0];
      }
    }
  }

  onSubmitUpdatedJourney(): void {

    this.journeyService.updateJourney(this.journey.journeyId, this.journey).subscribe(
      {
        next: j => {
          this.journey = j;
        },
        complete: () => {
          this.router.navigate(['/JourneyManager/control-panel', this.journey.journeyId]);
        }
      }
    );
  }
}
