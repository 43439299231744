import { Component, OnInit } from '@angular/core';
import { Alert } from 'src/alert/models/alert';
import { AlertService } from 'src/alert/services/alert.service';

@Component({
  selector: 'app-index-page',
  templateUrl: './index-page.component.html',
  styleUrls: ['./index-page.component.scss']
})
export class IndexPageComponent implements OnInit {

  public readonly url: string;

  constructor(private alertService: AlertService) {
  }

  ngOnInit(): void {
  }

  alert(): void {
    const alert = new Alert(4, 'G E F A H R !', 'Test-Nachricht an dich', true, 3000);
    this.alertService.alert(alert);
  }
}
