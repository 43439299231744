import { Injectable } from '@angular/core';
import { HubConnection, Subject } from '@microsoft/signalr';
import { WeatherData } from 'src/models/_classes';
import { SignalR } from '../classes/signal-r';
import { HubPaths } from '../signal-r.constants';
import { ApiUrl } from 'src/api/api.constants';

@Injectable({
  providedIn: 'root'
})
export class SignalRWeatherService {

  public static readonly weatherRStream: Subject<WeatherData> = new Subject<WeatherData>();
  
  private readonly signalR: SignalR;
  private readonly weatherHub = ApiUrl + HubPaths.WeatherHub;

  constructor() { 

    this.signalR = new SignalR(this.weatherHub);
    console.log(this.weatherHub);

    const hubConnection: HubConnection = this.signalR.getConnection();

    console.log(hubConnection);

    hubConnection.on('weatherUpdated', 
    (
        journeyId: number,
        name: string, 
        description: string, 
        temperature: number, 
        weatherId: number,
        created: string | Date
    ) => {
      
      const weatherData: WeatherData = new WeatherData();

      weatherData.journeyId = journeyId;
      weatherData.name = name;
      weatherData.description = description;
      weatherData.temperature = temperature;
      weatherData.weatherId = weatherId;
      weatherData.created = created;
      
      SignalRWeatherService.weatherRStream.next(weatherData);
          
    });
  }
}
