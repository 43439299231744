import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginMenuComponent } from './login-menu/login-menu.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiAuthorizationRoutingModule} from 'src/api-authorization/routing.api-authorization.module';
import { AuthorizeInterceptor } from 'src/api/Interceptors/Authorize/authorize.interceptor';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ApiAuthorizationRoutingModule
  ],
  providers: [
  ],
  declarations: [LoginMenuComponent, LoginComponent, LogoutComponent],
  exports: [LoginMenuComponent, LoginComponent, LogoutComponent]
})
export class ApiAuthorizationModule { }
