import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiUrl } from 'src/api/api.constants';
import { SignalR } from '../classes/signal-r';
import { HubPaths } from '../signal-r.constants';
import { PositionFlat } from 'src/models/_classes';

@Injectable({
  providedIn: 'root'
})
export class SignalRJourneyService {

  public static readonly positionRStream: Subject<PositionFlat> = new Subject<PositionFlat>();

  private readonly signalR: SignalR;
  private readonly journeyHub = ApiUrl + HubPaths.JourneyHub;

  constructor() { 

    this.signalR = new SignalR(this.journeyHub);
    this.signalR.getConnection().on("posUpdated", 
    (
        latitude: number, 
        longitude: number,
        accuracy: number,
        speed: number,
        heading: number,
        journeyId: number
    ) => {

      const position: PositionFlat = new PositionFlat();

      position.journeyId = journeyId;
      position.latitude = latitude;
      position.longitude = longitude;
      position.accuracy = accuracy;
      position.speed = speed;
      position.heading = heading;
      
      SignalRJourneyService.positionRStream.next(position);
    });
  }
}
