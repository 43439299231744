<div class="card" id="weatherCard" style="width: 100%">
    <div class="card-body">
        <h5 class="card-title">Wetter vor Ort</h5>
        <h6>Letzte Aktualisierung: {{ created }}</h6>
        <table class="table table-borderless">
            <tbody>
                <tr>
                    <td colspan="2">
                        <img id="weatherIcon" [src]="weatherIcon" />
                    </td>
                </tr>
                <tr>
                    <td id="weatherDataDescription" colspan="2">
                        {{ weatherData.description }}
                    </td>
                </tr>
                <tr>
                    <td>
                        Ort:
                    </td>
                    <td id="weatherDataName">
                        {{ weatherData.name }}
                    </td>
                </tr>
                <tr>
                    <td>
                        Temperatur:
                    </td>
                    <td id="weatherDataTemp">
                        {{ weatherData.temperature }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>