import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthorizeService } from 'src/api-authorization/authorize.service';

@Component({
  selector: 'app-nav-menu-bottom',
  templateUrl: './nav-menu-bottom.component.html',
  styleUrls: ['./nav-menu-bottom.component.scss']
})
export class NavMenuBottomComponent implements OnInit {

  public isAuthenticated: Observable<boolean>;

  public isMenuCollapsed = true;

  constructor(private authorizeService: AuthorizeService) { }

  ngOnInit(): void{
    this.isAuthenticated = this.authorizeService.isAuthenticated();
  }
}
