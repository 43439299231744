import { Component, Input, OnInit } from '@angular/core';
import { JourneyService } from '../services/journey.service';
import { Journey } from 'src/models/_classes';
import { JourneyStateUtilities } from 'src/utilities/journeystate-utilities';
import { JourneyState } from 'src/models/_interfaces';
import { BoundText } from '@angular/compiler/src/render3/r3_ast';

@Component({
  selector: 'app-journey-overview',
  templateUrl: './journey-overview.component.html',
  styleUrls: ['./journey-overview.component.scss']
})
export class JourneyOverviewComponent implements OnInit {

  journeyStateUtilities: JourneyStateUtilities;

  journeys: Journey[];
  journey: Journey;

  constructor(
    private journeyService: JourneyService
  ) {
    this.journeyStateUtilities = new JourneyStateUtilities();
  }

  ngOnInit(): void {
    if (this.journeys == null) {
      this.journeys = new Array<Journey>();
      this.getJourneys();
    }
  }

  getJourneys(): void {

    // temporary variables for checking execution time (see journey-service: local storage vs. server query)
    var begin;
    var end;

    begin = performance.now();
    this.journeyService.getJourneys().subscribe(
      {
        next: j => {
          this.journeys = new Array<Journey>();
          j.forEach(value => {
            this.journeys.push(value);
          });
        },
        complete: () => {
          this.journeys.sort(this.journeyStateUtilities.sortByState);
          this.journeyService.journeysUpdateRequired = false;
        }
      }
    );
    end = performance.now();
    console.log('journey-overview-component.getJourneys - Duration:' + (end - begin).toString());
  }

  checkIfStarted(state: JourneyState): boolean {
    return state === JourneyState.Started ? true : false;
  }
}
