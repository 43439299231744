<div class="row">
    <div class="col-sm-12">
        <app-map [lat]="lat" [lng]="lng"></app-map>
    </div>
</div>
<div class="row">
    <div class="col-sm-12">
        <div class="jumbotron">
            <h4>
                Reise: {{ journey?.name }}
            </h4>

            <table class="table table-bordered table-sm">
                <tbody>
                    <tr>
                        <th scope="row">Breite</th>
                        <td id="lat-val">{{lat}}</td>
                        <th scope="row">Longitude</th>
                        <td id="lng-val">{{lng}}</td>
                    </tr>
                    <tr>
                        <th scope="row">L&auml;ge</th>
                        <td id="ac-val">{{accuracy}}</td>
                        <th scope="row">Geschwindigkeit</th>
                        <td id="ac-val">{{speed}}</td>
                    </tr>
                    <tr *ngIf="journey?.bPositonUpdate">
                        <th scope="row">Position Intervall [m]</th>
                        <td>
                            {{ journey?.positionUpdateInterval }}
                        </td>
                        <th scope="row">Letzte Marke [m]</th>
                        <td style="background-color: rgb(170, 231, 255); " id="ac-val ">{{ positionDistanceToLast }}</td>
                    </tr>
                    <tr *ngIf="journey?.bWeatherUpdate">
                        <th scope="row">Wetterdaten Intervall [m]</th>
                        <td>
                            {{ journey?.weatherUpdateInterval }}
                        </td>
                        <th scope="row">Letzte Marke [m]</th>
                        <td style="background-color: rgb(170, 231, 255); " id="ac-val ">{{ weatherDistanceToLast }}</td>
                    </tr>
                </tbody>
            </table>
            <hr />
            <form>
                <div class="form-row mb-3">
                    <div class="col-md-6 col-sm-12">
                        <button type="button" class="btn btn-success" (click)="watchPosition();" style="width: 100%;">Start Autoupdate</button>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <button type="button" class="btn btn-warning" (click)="clearWatch();" style="width: 100%;">Stop Autoupdate</button>
                    </div>
                </div>
                <div class="form-row mb-3">
                    <div class="col-md-6 col-sm-12">
                        <button type="button" class="btn btn-primary" [routerLink]="['/Camera', journey?.journeyId]" routerLinkActive="router-link-active" style="width: 100%;">Kamera &ouml;ffnen</button>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <button type="button" class="btn btn-primary" [routerLink]="['/Dashboard', journey?.journeyId]" routerLinkActive="router-link-active" style="width: 100%;">Dashboard &ouml;ffnen</button>
                    </div>
                </div>
            </form>
            <form>
                <div class="form-row mb-3">
                    <div class="col-md-6 col-sm-12">
                        <button type="button" class="btn btn-primary" [routerLink]="['/JourneyManager/control-panel', journey?.journeyId]" routerLinkActive="router-link-active" style="width: 100%;">Control-Panel &ouml;ffnen</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>