import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Journey, JourneyGuest, PauleoTravelGuest, PauleoTravelUser } from 'src/models/_classes';
import { GuestService } from '../services/guest.service';
import { JourneyService } from '../services/journey.service';

@Component({
  selector: 'app-journey-add-guests',
  templateUrl: './journey-add-guests.component.html',
  styleUrls: ['./journey-add-guests.component.scss']
})
export class JourneyAddGuestsComponent implements OnInit {

  journey: Journey;
  host: PauleoTravelUser;
  guests: PauleoTravelGuest[];
  tmpGuests: PauleoTravelGuest[];

  constructor(
    private guestService: GuestService,
    private journeyService: JourneyService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.tmpGuests = new Array<PauleoTravelGuest>();
  }

  ngOnInit(): void {
    const journeyId = this.route.snapshot.paramMap.get('journeyId');
    this.getJourney(parseInt(journeyId, 10));
  }

  getJourney(jounreyId: number): void {

    this.journeyService.getJourney(jounreyId).subscribe(
      {
        next: j => {
          if (j != null) {
            this.journey = j;
            this.getJourneyGuests(j);
          }
        }
      }
    );
  }

  getJourneyGuests(journey: Journey): void {

    const guestIds: number[] = new Array<number>();
    journey.journeyGuests?.forEach(jg => { guestIds.push(jg.guestId); });

    this.guestService.getGuests().subscribe(
      {
        next: g => { this.guests = g; }
      }
    );
  }

  onSubmitUpdatedJourney(): void {

    this.journey.journeyGuests = [];

    this.tmpGuests.forEach(jg => {
      const journeyGuest: JourneyGuest = new JourneyGuest();

      journeyGuest.guestId = jg.id;
      journeyGuest.journeyId = this.journey.journeyId;

      this.journey.journeyGuests.push(journeyGuest);
    });

    this.journeyService.updateJourney(this.journey.journeyId, this.journey).subscribe(
      {
        next: j => {
          this.journey = j;
        },
        complete: () => {
          this.router.navigate(['/JourneyManager/control-panel', this.journey.journeyId]);
        }
      }
    );
  }

  setupGuestList(guestId: number, e: Event): void {

    const eventTarget: HTMLInputElement = e.target as HTMLInputElement;
    const index = this.tmpGuests.findIndex(tjg => tjg.id === guestId);

    const guest: PauleoTravelGuest = this.guests.find(g => g.id === guestId);

    if (guest)
    {
      if (eventTarget.checked === true) {
        this.tmpGuests.push(guest);
      }
      else {
        this.tmpGuests.splice(index, 1);
      }
    }
  }
}
