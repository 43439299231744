import { Injectable } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { ApiUrl } from 'src/api/api.constants';
import { Media } from 'src/models/_classes';
import { SignalR } from '../classes/signal-r';
import { HubPaths } from '../signal-r.constants';

@Injectable({
  providedIn: 'root'
})
export class SignalRMediaService {

  private readonly signalR: SignalR;
  private readonly mediaHub = ApiUrl +  HubPaths.MediaHub;

  public static $mediaSrStream: Subject<Media> = new Subject<Media>();

  constructor() { 

    this.signalR = new SignalR(this.mediaHub);
    console.log(this.mediaHub);

    const hubConnection: HubConnection = this.signalR.getConnection();

    console.log(hubConnection);

    hubConnection.on('mediaUpdated', 
    (
      fileName: string,
      dataUrlPrefix: string,
      data: string, 
      fileType: string,
      created: string | Date,
      journeyId: number
      
    ) => {
      
      const media: Media = new Media();

        media.journeyId = journeyId;
        media.dataUrlPrefix = dataUrlPrefix;
        media.data = data;
        media.fileName = fileName;
        media.fileType = fileType;
        media.created = created;

        SignalRMediaService.$mediaSrStream.next(media);    
    });
  }
}
