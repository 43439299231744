import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { IDeviceOrientation } from 'src/models/_interfaces';

@Injectable({
  providedIn: 'root'
})
export class DeviceEventSeviceService {

  $deviceOrientationEvent: Observable<DeviceOrientationEvent>;

  private deviceOrientation: IDeviceOrientation;
  
  constructor(

  ) { 
    
    this.deviceOrientation = {
      alpha: 0,
      beta: 0,
      gamma: 0,
      absolute: false
    }
    
    this.$deviceOrientationEvent = fromEvent<DeviceOrientationEvent>(window, 'deviceorientation');
    this.$deviceOrientationEvent.subscribe(
      {
        next: (o) => { 
          this.deviceOrientation.alpha = o.alpha || 0;
          this.deviceOrientation.beta = o.beta || 0;
          this.deviceOrientation.gamma = o.gamma || 0;
          this.deviceOrientation.absolute = o.absolute || false
        }
      }
    );
  }

  getDeviceOrienntation(): IDeviceOrientation {
    return this.deviceOrientation;
  }
}

