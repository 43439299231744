import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiPaths, ApiUrl } from 'src/api/api.constants';
import { PauleoTravelGuest } from 'src/models/_classes';


@Injectable({
  providedIn: 'root'
})
export class GuestService {

  private readonly GuestApiPath = ApiUrl + ApiPaths.Guest;
  private readonly subscription;

  private GUESTS: PauleoTravelGuest[];
  public guestsUpdateRequired: boolean;

  constructor(
    private httpclient: HttpClient
  ) 
  { 
    this.guestsUpdateRequired = true;
    this.subscription = this.getGuests().subscribe(
      {
        next: (g) => { this.GUESTS = g; },
        complete: () => { /* this.guestsUpdateRequired = false; */ },
        // error: (err) => { console.log(err) }
    });
  }

  getGuests(): Observable<PauleoTravelGuest[]> {

    // check if api call required: only if data have changed meanwhile
    /* const updateRequired: boolean = this.guestsUpdateRequired ? true : false;

    if (!updateRequired) {
      return new Observable<PauleoTravelGuest[]>(
        (s) => 
        { 
          s.next(this.GUESTS);
          s.complete();
        });
      } */
    return this.httpclient.get<PauleoTravelGuest[]>(this.GuestApiPath);    
  }

  getGuest(id: number): Observable<PauleoTravelGuest> {

    // check if api call required: only if data have changed meanwhile
    // const updateRequired: boolean = this.guestsUpdateRequired  ? true : false;

    /*if (!updateRequired) {
      return new Observable<PauleoTravelGuest>((s) => s.next(
        this.GUESTS.find(g => g.id == id)
      ));
    } */
    return this.httpclient.get<PauleoTravelGuest>(this.GuestApiPath + '/' + id);
  }

  addGuest(guest: PauleoTravelGuest): Observable<PauleoTravelGuest> {
    return this.httpclient.post<PauleoTravelGuest>(this.GuestApiPath, guest);
  }

  updateGuest(id: number, guest: PauleoTravelGuest): Observable<PauleoTravelGuest> {
    return this.httpclient.put<PauleoTravelGuest>(this.GuestApiPath + '/' + id, guest);
  }

  deleteGuest(id: string): Observable<PauleoTravelGuest> {
    return this.httpclient.delete<PauleoTravelGuest>(this.GuestApiPath + '/' + id);
  }
}




