import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PositionService } from 'src/app/services/position.service';
import { Journey, PositionFlat } from 'src/models/_classes';
import { SignalRJourneyService } from 'src/signal-r/services/signal-r-journey.service';

@Component({
  selector: 'app-position-sr',
  templateUrl: './position-sr.component.html',
  styleUrls: ['./position-sr.component.scss']
})
export class PositionSrComponent implements OnInit {

  private readonly positionStreamObserver;

  private position: PositionFlat; //PositionFlat -> simplified Navigator Position
  
  @Input() journey: Journey;

  latitude: number;
  longitude: number;
  speed: number | null;
  accuracy: number;
  heading: number | null;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private srJourneyService: SignalRJourneyService,
    private positionService: PositionService
  ) { 
    
    this.position = new PositionFlat();
    this.latitude = 0.0;
    this.longitude = 0.0;
    this.speed = 0.0;
    this.accuracy = 0;
    
    this.positionStreamObserver = {
      next: (pos: PositionFlat) => {
        this.latitude = pos.latitude;
        this.longitude = pos.longitude;
        this.speed = pos.speed;
        this.accuracy = pos.accuracy;
        this.heading = pos.heading;
      }
    }
    SignalRJourneyService.positionRStream.subscribe(this.positionStreamObserver);
  }

  ngOnInit(): void {
  }

}
