<div *ngIf="!bJourneyParamSuccess">
    <div class="row">
        <div class="col-sm-12">
            <label for="selectState">W&auml;le eine Reise</label>
            <select #select (change)="onJourneySelected(select.value)" class="form-control">
                <option>...</option>
                <option *ngFor="let journey of journeys" [value]="journey.journeyId">{{ journey.name }}</option>
            </select>
        </div>
    </div>
</div>
<div *ngIf="bJourneyParamSuccess">
    <div class="row">
        <div id="tile-tl" class="col-sm-12 mb-3">
            <app-position-sr [journey]="journey"></app-position-sr>
        </div>
    </div>
    <div class="row">
        <div id="tile-bl" class="col-sm-12 mb-3">
            <app-media-sr [journey]="journey"></app-media-sr>
        </div>
    </div>
    <div class="row">
        <div id="tile-tr" class="col-md-6 col-sm-12 mb-3">
            <app-weather-sr [journey]="journey"></app-weather-sr>
        </div>
        <div id="tile-br" class="col-md-6 col-sm-12 mb-3">
            <div class="card" style="width: 100%">
                <div class="card-body">
                    <h5 class="card-title">Stau</h5>
                    <h6 class="card-subtitle mb-2 text-muted">Situation vor Ort</h6>
                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    <h5 class="card-title">Sehenswürdigkeiten</h5>
                    <h6 class="card-subtitle mb-2 text-muted">Situation vor Ort</h6>
                </div>
            </div>
        </div>
    </div>
</div>
