import { ErrorHandler, Injectable } from '@angular/core';
import { NavigationError } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorService implements ErrorHandler {

  private error: Error;

  constructor() {
  }

  public handleError(error: Error | any): void {
  
    if (error instanceof NavigationError) {
      console.error('Instance of NavigationError: ID' + error.id + ' - URL: ' + error.url + ' - error: ' + error.error);
    }
    else if (error instanceof Error) {
      console.error('Instance of Error. Message: ' + error.message)
    }
    else {
      console.error('Nicht näher definierter Fehler aufgetreten: ' + error)
    }
  }

  public clearErrorMessage(): string {
    return '';
  }
}
