<div class="row">
    <div class="col-sm-12">
        <a class="nav-link d-flex flex-column" [routerLink]="['/GuestConfiguration']">
            <i class="bi bi-plus-circle add-item"></i>
        </a>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <table *ngIf="guests != null" class="table table-bordered">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">E-Mail</th>
                    <th scope="col">&Ouml;ffnen</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let guest of guests">
                    <td>{{ guest.userName }}</td>
                    <td>{{ guest.email }}</td>
                    <td><button type="button" class="btn btn-primary btn-lg" [routerLink]="['/GuestConfiguration', guest.id]" routerLinkActive="router-link-active">+</button></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>