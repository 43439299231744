import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiPaths, ApiUrl } from 'src/api/api.constants';
import { Journey } from 'src/models/_classes';
import { IMedia } from 'src/models/_interfaces';
import { Media } from 'src/models/_classes';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  private readonly MediaApiPath = ApiUrl + ApiPaths.Media;

  constructor(
    private httpClient: HttpClient
  ) { }

  addMedia(dataUrl: string, journey: Journey): Observable<IMedia> {

    const timeStamp: number = Date.now();

    const mediaData: Media = new Media();

    mediaData.data = this.stripDataUrl(dataUrl, 'data');
    mediaData.dataUrlPrefix = this.stripDataUrl(dataUrl, 'prefix');
    mediaData.fileName = 'img_' + journey.journeyId + '_' +  timeStamp.toString();
    mediaData.fileType = 'image/png';
    mediaData.journeyId = journey.journeyId;
    mediaData.ownerId = 0;

    /*const payload: IMedia = {
      data : this.stripDataUrl(dataUrl, 'data'),
      dataUrlPrefix: this.stripDataUrl(dataUrl, 'prefix'),
      fileName : 'img_' + journey.journeyId + '_' +  timeStamp.toString(),
      fileType : 'image/png',
      journeyId: journey.journeyId,
      ownerId: 0,
    };*/

    return this.httpClient.post<IMedia>(this.MediaApiPath, mediaData);
  }

  public stripDataUrl(dataUrl: string, urlPart?: string): string {
    
    // if prefix parameter is set 
    if (urlPart === 'prefix') {
      return dataUrl.substring(0, (dataUrl.indexOf(',') + 1))
    }
    return dataUrl.substring((dataUrl.indexOf(',') + 1), dataUrl.length);
  }

  public concatDataUrl(prefix: string, data: string): string {
    return prefix.toString() + data.toString();
  }
}
