import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-guestcreator',
  templateUrl: './guestcreator.component.html',
  styleUrls: ['./guestcreator.component.scss']
})
export class GuestcreatorComponent implements OnInit {

  private readonly configurationPath: string = '/GuestConfiguration';

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  onSubmitCreateInitialized(): void {
    this.router.navigate([this.configurationPath]);
  }

}
