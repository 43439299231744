import { Component, OnInit } from '@angular/core';
import { JourneyService } from '../services/journey.service';
import { Journey } from 'src/models/_classes';
import { Router } from '@angular/router';


@Component({
  selector: 'app-journeymanager',
  templateUrl: './journeymanager.component.html',
  styleUrls: ['./journeymanager.component.scss']
})
export class JourneymanagerComponent implements OnInit {

  public journey: Journey;
  public journeys: Journey[];

  constructor(
    private journeyService: JourneyService,
    private router: Router
  )
  {}

  ngOnInit(): void {
  }

  getJourney(jounreyId: number): void {

    this.journeyService.getJourney(jounreyId).subscribe(
      {
        next: j => { this.journey = j; },
        complete: () => { console.log('journeyservice.getJourney: complete called'); }
      }
    );
  }

  addJourney(journey: Journey): void {

    this.journeyService.addJourney(journey).subscribe(
      {
        next: j => {  },
        complete: () => { console.log('journeyservice.addJourney: complete called'); }
      }
    );
  }

  updateJourney(journey: Journey): void {

  }

}
