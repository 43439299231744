import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Journey, PauleoTravelGuest } from 'src/models/_classes';
import { JourneyState } from 'src/models/_interfaces';
import { JourneyStateUtilities } from 'src/utilities/journeystate-utilities';
import { GuestService } from '../services/guest.service';
import { JourneyService } from '../services/journey.service';
import { MessageService } from '../services/message.service';
import { ShareService } from '../services/share.service';
import { AlertService } from 'src/alert/services/alert.service';
import { Alert } from 'src/alert/models/alert';


@Component({
  selector: 'app-journey-control-panel',
  templateUrl: './journey-control-panel.component.html',
  styleUrls: ['./journey-control-panel.component.scss']
})
export class JourneyControlPanelComponent implements OnInit {

  guests: PauleoTravelGuest[];

  public journey: Journey;
  public journeyStateFriendly: any;
  public journeyStateUtilities: JourneyStateUtilities;
  public journeyClasses: Record<string, boolean> = {};
  public journeyStateOptions: Array<string>;

  bInvitationPrepared: boolean;
  bInvitationSent: boolean;

  constructor(
    private journeyService: JourneyService,
    private guestService: GuestService,
    private messageService: MessageService,
    private shareService: ShareService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService
  ) {
    this.journeyStateUtilities = new JourneyStateUtilities();
    this.setJourneyStateSelectOptions();
  }

  ngOnInit(
  ): void {
    const journeyId = this.route.snapshot.paramMap.get('journeyId');
    this.getJourney(parseInt(journeyId, 10));
  }

  getJourney(jounreyId: number): void {
    this.journeyService.getJourney(jounreyId).subscribe(
      {
        next: j => {
          this.journey = j;
          this.journeyStateFriendly = this.journeyStateUtilities.getFriendlyJourneyState(j.state);
        },
        complete: () => {
          this.setJourneyClasses();
          this.getJourneyGuests(this.journey);
        }
      }
    );
  }

  getJourneyGuests(journey: Journey): void {

    const guestIds: number[] = new Array<number>();
    journey.journeyGuests?.forEach(jg => { guestIds.push(jg.guestId); });

    this.guestService.getGuests().subscribe(
      {
        next: g => { this.guests = g; },
        complete: () => { this.guests = this.guests.filter((g) => guestIds.includes(g.id)); }
      }
    );
  }

  updateJourney(id: number, journey: Journey): void {

    journey.state = this.journeyStateUtilities.getJourneyState(this.journeyStateFriendly);

    this.journeyService.updateJourney(id, journey).subscribe(
      {
        next: j => {
          this.journey = j;
          this.ngOnInit();
        }
      }
    );
  }

  shareJourney(): void {
    this.shareService.share(this.journey);
  }

  stageInvitation(): void {
    this.bInvitationPrepared = true;
    this.alertService.alert(new Alert(2, 'INFO:', 'Du willst die Einladungen verschicken? Alle unten aufgeführten Gäste erhalten eine Einladung per E -Mail.', true, 10000));
  }

  inviteGuests(): void {

    const dateNow: Date = new Date(Date.now());
    const journeyDateBegin = new Date(this.journey.dateBegin);

    if (journeyDateBegin < dateNow)
    {
      this.alertService.alert(new Alert(
        3,
        'ACHTUNG:',
        'Der geplante Start der Reise liegt in der Vergangenheit. Bitte überprüfe das.',
        true,
        10000
      )
    );
      return;
    }

    this.messageService.postMessage(this.journey.journeyId).subscribe(
      {
        next: (data) =>
        {
          if ( data === this.journey.journeyId) // success
          {
            this.router.navigate(['/JourneyManager']);
          }
          else {
            this.alertService.alert(
              new Alert(
                3,
                'ACHTUNG:',
                'Beim Versand der Einladungen ist etwas schiefgelaufen! Bitte überprüfe zunächst die E-Mail-Adressen deiner Freunde.',
                true,
                1000
              )
            );
          }
        }
      }
    );
  }

  go(journeyId: number): void {
    if (typeof this.journey.journeyGuests === 'undefined' || this.journey.journeyGuests === null) {
      this.alertService.alert(
        new Alert(
          3,
          'ACHTUNG:',
          'Der Reise sind noch keine Gäste zugeordnet!',
          true,
          5000
        )
      );
    }
    else {
      this.router.navigate(['/Position', journeyId]);
    }
    
  }

  setJourneyStateSelectOptions(): void {
    this.journeyStateOptions = new Array<string>();
    this.journeyStateOptions.push(this.journeyStateUtilities.CONFIGURED);
    this.journeyStateOptions.push(this.journeyStateUtilities.STARTED);
    this.journeyStateOptions.push(this.journeyStateUtilities.HIDDEN);
    this.journeyStateOptions.push(this.journeyStateUtilities.STOPPED);
    this.journeyStateOptions.push(this.journeyStateUtilities.FINISHED);
    this.journeyStateOptions.push(this.journeyStateUtilities.CLOSED);
  }

  setJourneyClasses(): void {
    this.journeyClasses = {
       jCreated: this.journey.state === JourneyState.Created,
       jConfigured: this.journey.state === JourneyState.Configured,
       jStarted: this.journey.state === JourneyState.Started,
       jHidden: this.journey.state === JourneyState.Hidden,
       jStopped: this.journey.state === JourneyState.Stopped,
       jFinished: this.journey.state === JourneyState.Finished,
       jClosed: this.journey.state === JourneyState.Closed,
    };
  }
}
