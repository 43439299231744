<div class="row">
    <div class="col-md-12">
        <form id="form-addguest" [formGroup]="guestData" novalidate>
            <div class="form-group">
                <label for="guestname">Name des Gasts</label>
                <input type="text" class="form-control" id="guestname" name="guestname" formControlName="guestName" [placeholder]="guestNameDefault" required>
                <div *ngIf="(gd.guestName.touched || gd.guestName.dirty) && gd.guestName.errors" class="alert alert-danger">
                    <div *ngIf="gd.guestName.errors.required">
                        Bitte einen Namen eingeben.
                    </div>
                    <div *ngIf="gd.guestName.errors.minlength">
                        Der Name muss mindestens 4 Zeichen lang sein.
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="guestemail">E-Mail des Gasts</label>
                <input type="email" class="form-control" id="guestemail" name="guestemail" formControlName="guestEMail" [placeholder]="guestEMailDefault" required email>
                <div *ngIf="(gd.guestEMail.touched || gd.guestEMail.dirty) && gd.guestEMail.errors" class="alert alert-danger">
                    <div *ngIf="gd.guestEMail.errors.required">
                        Bitte eine E-Mail-Adresse eingeben.
                    </div>
                    <div *ngIf="gd.guestEMail.errors.email">
                        Die E-Mail-Adresse muss g&uuml;tig sein.
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="info">Info</label>
                <textarea class="form-control" id="info" name="info" rows="3" formControlName="guestInfo"></textarea>
            </div>

            <div class="form-group float-left">
                <button type="button" (click)="onSubmitCreatedOrUpdated()" id="save" class="btn btn-primary" [disabled]="gd.guestName.errors || gd.guestEMail.errors">Speichern</button>
                <button type="button" [routerLink]="['/GuestOverview']" routerLinkActive="router-link-active" class="btn btn-primary">Abbrechen</button>
            </div>
        </form>
    </div>
</div>