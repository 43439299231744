import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Journey } from 'src/models/_classes';
import { JourneyState } from 'src/models/_interfaces';
import { PLDatePickerAdapter } from 'src/utilities/datepicker-adapter';
import { JourneyService } from '../services/journey.service';
import { JourneyStateUtilities } from 'src/utilities/journeystate-utilities';

@Component({
  selector: 'app-journey-configuration-panel',
  templateUrl: './journey-configuration-panel.component.html',
  styleUrls: ['./journey-configuration-panel.component.scss']
})
export class JourneyConfigurationPanelComponent implements OnInit {

  public journey: Journey;
  public journeyStateUtilities: JourneyStateUtilities;
  public journeyStateFriendly: any;
  public journeyClasses: Record<string, boolean> = {};

  public readonly journeyNameDefault = 'Musterbeschreibung - bitte ersetzen';
  public readonly journeyDescriptionDefault = 'Musterbeschreibung - bitte ersetzen';
  public readonly journeyStartDefault = 'Musterausgangspunkt - bitte ergänzen';
  public readonly journeyFinishDefault: 'Musterziel - bitte ergänzen';
  public readonly journeyDateBeginDefault = '10.12.2012';

  public journeyData: FormGroup;

  bPersistentStorage: boolean;
  doPositonUpdate: boolean;
  doWeatherUpdate: boolean;
  PositionUpdateInterval: number;
  WeatherUpdateInterval: number;

  PositionUpdateIntervalOptions: Array<number>;
  WeatherUpdateIntervalOptions: Array<number>;

  public dateBegin: any;

  constructor(
    private journeyService: JourneyService,
    private router: Router,
    private route: ActivatedRoute,
    private datepickerAdapter: PLDatePickerAdapter,
    private formBuilder: FormBuilder
  )
  {
    this.journeyStateUtilities = new JourneyStateUtilities();
    this.PositionUpdateIntervalOptions = [100, 500, 1000, 5000, 10000];
    this.WeatherUpdateIntervalOptions = [1000, 5000, 10000, 25000, 50000]
    this.createForm();
  }

  ngOnInit(): void {

    const journeyId = parseInt(this.route.snapshot.paramMap.get('journeyId'), 10);

    if (journeyId > 0) {
      this.getJourney(journeyId);
    }
  }

  onSubmitUpdated(): void {

    const journeyId = this.journey.journeyId;
    const dateBegin = this.datepickerAdapter.toModel(this.dateBegin);

    if (this.journey != null) {

      this.journey.description = this.jd.journeyDescription.value === '' ? this.journey.description : this.jd.journeyDescription.value;
      this.journey.dateBegin = dateBegin === '' ? this.journey.dateBegin : dateBegin;
      this.journey.start = this.jd.journeyStart.value === '' ? this.journey.start : this.jd.journeyStart.value;
      this.journey.finish = this.jd.journeyFinish.value === '' ? this.journey.finish : this.jd.journeyFinish.value;
      this.journey.isActive = true;

      if (this.journey.state === JourneyState.Created) {
        this.journey.state = JourneyState.Configured;
      }
      else {
        const stateFriendly = this.jd.journeyState.value;
        this.journey.state = stateFriendly === '' ?
          this.journey.state : this.journeyStateUtilities.getJourneyState(stateFriendly);
      }

      this.journey.bPersistentStorage = this.jd.journeyBPersistantStorage.value;
      this.journey.bPositonUpdate = this.jd.jounrneyBPositonUpdate.value;
      this.journey.bWeatherUpdate = this.jd.journeyBWeatherUpdate.value;
      this.journey.positionUpdateInterval =  parseInt(this.jd.journeyPositionUpdateInterval.value, 10);
      this.journey.weatherUpdateInterval =  parseInt(this.jd.journeyWeatherUpdateInverval.value, 10);

      this.updateJourney(journeyId, this.journey);
    }
  }

  getJourney(jounreyId: number): void {

    this.journeyService.getJourney(jounreyId).subscribe(
      {
        next: j => {
          this.journey = j;
          
          this.journeyStateFriendly = this.journeyStateUtilities.getFriendlyJourneyState(j.state);
          this.setJourneyClasses();
          
          this.bPersistentStorage = j.bPersistentStorage;
          this.doPositonUpdate = j.bPositonUpdate;
          this.doWeatherUpdate = j.bWeatherUpdate;
          console.log('doPositonUpdate: ' + this.doPositonUpdate);
          console.log('doWeatherUpdate: ' + this.doWeatherUpdate);
          this.PositionUpdateInterval = j.positionUpdateInterval;
          this.WeatherUpdateInterval = j.weatherUpdateInterval;

          this.initForm(j);
        }
      }
    );
  }

  updateJourney(id: number, journey: Journey): void {

    this.journeyService.updateJourney(id, journey).subscribe(
      {
        next: j => {
          this.journey = j;
          if (this.journey.state > JourneyState.Created) {
            this.router.navigate(['/JourneyManager/control-panel', this.journey.journeyId]);
          }
        }
      }
    );
  }

  createForm(): void {

    this.journeyData = this.formBuilder.group({

      journeyName: ['', [Validators.required, Validators.minLength(4)]],
      journeyDescription: ['', [Validators.required]],
      journeyStart: ['', [Validators.required]],
      journeyFinish: ['', [Validators.required]],
      journeyDateBegin: '',
      journeyState: '',
      journeyBPersistantStorage: false,
      jounrneyBPositonUpdate: false ,
      journeyBWeatherUpdate: false,
      journeyWeatherUpdateInverval: 0,
      journeyPositionUpdateInterval: 0
     });
  }

  initForm(journey: Journey): void {

    this.jd.journeyName.setValue(journey.name);
    this.jd.journeyDescription.setValue(journey.description);
    this.jd.journeyStart.setValue(journey.start);
    this.jd.journeyFinish.setValue(journey.finish);
    this.dateBegin = this.datepickerAdapter.fromModel(journey.dateBegin.toString());
    this.jd.journeyBPersistantStorage.setValue(journey.bPersistentStorage);
    this.jd.jounrneyBPositonUpdate.setValue(journey.bPositonUpdate);
    this.jd.journeyBWeatherUpdate.setValue(journey.bWeatherUpdate);
    this.jd.journeyPositionUpdateInterval.setValue(journey.positionUpdateInterval);
    this.jd.journeyWeatherUpdateInverval.setValue(journey.weatherUpdateInterval);

    this.jd.journeyBPersistantStorage.disable();
    this.jd.journeyBWeatherUpdate.disable();
    this.jd.jounrneyBPositonUpdate.disable();

    journey.bPositonUpdate ? this.jd.journeyPositionUpdateInterval.enable() : this.jd.journeyPositionUpdateInterval.disable();
    journey.bWeatherUpdate ? this.jd.journeyWeatherUpdateInverval.enable() : this.jd.journeyWeatherUpdateInverval.disable();
  }

  get jd(): { [key: string]: AbstractControl } { return this.journeyData.controls; }

  setJourneyClasses(): void {
    this.journeyClasses = {
       jCreated: this.journey.state === JourneyState.Created,
       jConfigured: this.journey.state === JourneyState.Configured,
       jStarted: this.journey.state === JourneyState.Started,
       jHidden: this.journey.state === JourneyState.Hidden,
       jStopped: this.journey.state === JourneyState.Stopped,
       jFinished: this.journey.state === JourneyState.Finished,
       jClosed: this.journey.state === JourneyState.Closed,
    };
  }
}
