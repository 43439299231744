<div class="row">
    <div class="col-md-12">
        <table class="table table-hover" id="guestoverview">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">EMail</th>
                    <th scope="col">Hinzuf&uuml;gen ja/nein</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let guest of guests">
                    <td>{{ guest.id }}</td>
                    <td>{{ guest.userName }}</td>
                    <td>{{ guest.email}}</td>
                    <td>
                        <div class="form-group form-check">
                            <input type="checkbox" class="form-check-input" (change)="setupGuestList(guest.id, $event)">
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <form>
            <div class="btn-group mr-3" role="group" aria-label="first group">
                <button id="submitGuests" class="btn btn-primary" (click)="onSubmitUpdatedJourney()">Speichern</button>
            </div>
            <div class="btn-group mr-3" role="group" aria-label="second group">
                <button type="button" class="btn btn-warning btn" [routerLink]="['/JourneyManager/control-panel', journey.journeyId]" outerLinkActive="router-link-active">Abbrechen</button>
            </div>
        </form>
    </div>
</div>