import { Injectable } from '@angular/core';
import { stringify } from 'querystring';
import { Journey } from 'src/models/_classes';

@Injectable({
  providedIn: 'root'
})

export class ShareService {

  private html: string;

  constructor() {

  }

  public share(journey: Journey): void {

    if (navigator.canShare) {
      navigator.share({
        title: journey.name + ' - ' + journey.description,
        text: 'Verfolge unsere Reise von ' + journey.start + ' nach ' + journey.finish,
        url: 'https://travel.pauleo.de/journey'
      }).then(() => {});
    }
  }

  private buildHtml(): string {
    let html: string[];

    html.push(
      `<html>`,
      `<body>`,
      `<h4>`,
      `</h4>`,
      `<html>`,
      `<html>`,
      `<html>`,
      `</body>`,
      `</html>`
    );

    return html.join(``);

  }
}


