import { iconRoot } from "../signal-r-weather-constants";

export class WeatherUtilities {

    constructor() {
        
    }

    public static getIconPath(weatherCode: number): string {

        let prefix: string = 'wi-';

        // If we are not in the ranges mentioned above, add a day/night prefix.
        if (!(weatherCode > 699 && weatherCode < 800) && !(weatherCode > 899 && weatherCode < 1000)) {
            prefix = prefix + 'day-'; 
        }

        try {
            const icon = WeatherUtilities.mapWeatherCodeToIcon(weatherCode);
            const iconPath: string = iconRoot + '/' + prefix + icon + '.svg';
            return iconPath;
        }
        catch(e) {
            console.error(e);
            return "";
        }
    }

    public static mapWeatherCodeToIcon(weatherCode: number): string {
        switch(weatherCode) {
            case 200: {
            return "storm-showers";
            
            }
            case 201: {
            return "storm-showers";
            
            }
            case 202: {
            return "storm-showers";
            
            }
            case 210: {
            return "storm-showers";
            
            }
            case 211: {
            return "thunderstorm";
            
            }
            case 212: {
            return "thunderstorm";
            
            }
            case 221: {
            return "thunderstorm";
            
            }
            case 230: {
            return "storm-showers";
            
            }
            case 231: {
            return "storm-showers";
            
            }
            case 232: {
            return "storm-showers";
            
            }
            case 300: {
            return "sprinkle";
            
            }
            case 301: {
            return "sprinkle";
            
            }
            case 302: {
            return "sprinkle";
            
            }
            case 310: {
            return "sprinkle";
            
            }
            case 311: {
            return "sprinkle";
            
            }
            case 312: {
            return "sprinkle";
            
            }
            case 313: {
            return "sprinkle";
            
            }
            case 314: {
            return "sprinkle";
            
            }
            case 321: {
            return "sprinkle";
            
            }
            case 500: {
            return "rain";
            
            }
            case 501: {
            return "rain";
            
            }
            case 502: {
            return "rain";
            
            }
            case 503: {
            return "rain";
            
            }
            case 504: {
            return "rain";
            
            }
            case 511: {
            return "rain-mix";
            
            }
            case 520: {
            return "showers";
            
            }
            case 521: {
            return "showers";
            
            }
            case 522: {
            return "showers";
            
            }
            case 531: {
            return "showers";
            
            }
            case 600: {
            return "snow";
            
            }
            case 601: {
            return "snow";
            
            }
            case 602: {
            return "snow";
            
            }
            case 611: {
            return "sleet";
            
            }
            case 612: {
            return "sleet";
            
            }
            case 615: {
            return "rain-mix";
            
            }
            case 616: {
            return "rain-mix";
            
            }
            case 620: {
            return "rain-mix";
            
            }
            case 621: {
            return "rain-mix";
            
            }
            case 622: {
            return "rain-mix";
            
            }
            case 701: {
            return "sprinkle";
            
            }
            case 711: {
            return "smoke";
            
            }
            case 721: {
            return "day-haze";
            
            }
            case 731: {
            return "cloudy-gusts";
            
            }
            case 741: {
            return "fog";
            
            }
            case 751: {
            return "cloudy-gusts";
            
            }
            case 761: {
            return "dust";
            
            }
            case 762: {
            return "smog";
            
            }
            case 771: {
            return "day-windy";
            
            }
            case 781: {
            return "tornado";
            
            }
            case 800: {
            return "sunny";
            
            }
            case 801: {
            return "cloudy";
            
            }
            case 802: {
            return "cloudy";
            
            }
            case 803: {
            return "cloudy";
            
            }
            case 804: {
            return "cloudy";
            
            }
            case 900: {
            return "tornado";
            
            }
            case 901: {
            return "hurricane";
            
            }
            case 902: {
            return "hurricane";
            
            }
            case 903: {
            return "snowflake-cold";
            
            }
            case 904: {
            return "hot";
            
            }
            case 905: {
            return "windy";
            
            }
            case 906: {
            return "hail";
            
            }
            case 951: {
            return "sunny";
            
            }
            case 952: {
            return "cloudy-gusts";
            
            }
            case 953: {
            return "cloudy-gusts";
            
            }
            case 954: {
            return "cloudy-gusts";
            
            }
            case 955: {
            return "cloudy-gusts";
            
            }
            case 956: {
            return "cloudy-gusts";
            
            }
            case 957: {
            return "cloudy-gusts";
            
            }
            case 958: {
            return "cloudy-gusts";
            
            }
            case 959: {
            return "cloudy-gusts";
            
            }
            case 960: {
            return "thunderstorm";
            
            }
            case 961: {
            return "thunderstorm";
            
            }
            case 962: {
            return "cloudy-gusts"
            
            }
            default: {
            return "sunny";
            
            }
        }
    }
}