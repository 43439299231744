<div class="card" style="width: 100%">
    <div class="card-body">
        <h5 class="card-title">Geo-Daten</h5>
        <table class="table table-bordered table-sm">
            <thead>
                <tr>
                    <th scope="col">Breite</th>
                    <th scope="col">Länge</th>
                    <th scope="col">Genauigkeit</th>
                    <th scope="col">Geschwindigkeit</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ latitude }}</td>
                    <td>{{ latitude }}</td>
                    <td>{{ accuracy }}</td>
                    <td>{{ speed }}</td>
                </tr>
            </tbody>
        </table>
        <h6 class="card-subtitle mb-2 text-muted">Karte</h6>
        <app-maps [lat]="latitude" [lng]="longitude"></app-maps>
        <!--<img width="100%" src="../../assets/images/sea-with-ship-horizont.png">-->
    </div>
</div>
