<nav class="navbar navbar-expand-sm navbar-light pl-blue fixed-top p-1">
    <div class="container">
        <a class="navbar-brand" style="font-family: Pirata One, cursive; text-align: center; font-size: 1.8em; color: #F00;" href="#">PL</a>
        <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
            <app-login-menu></app-login-menu>
        </div>
    </div>
</nav>