import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { PositionService } from '../services/position.service';
import { IPosition } from 'src/models/_interfaces';
import { ErrorService } from '../services/error.service';
import NoSleep from 'nosleep.js';
import { Journey } from 'src/models/_classes';
import { JourneyService } from '../services/journey.service';
import { ActivatedRoute } from '@angular/router';
import { WeahterService } from '../services/weahter.service';

@Component({
  selector: 'app-geolocation',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.scss']
})
export class PositionComponent implements OnInit {

  public static currentPosition: IPosition;
  public static positionStream: Subject<IPosition>;

  noSleep: NoSleep;

  public lat: number;
  public lng: number;
  public accuracy: number;
  public speed: number;
  public positionDistanceToLast: number;
  public weatherDistanceToLast: number;
  public watchId: number;

  public errMsg: string;

  private readonly OBSERVER;

  journey: Journey;

  constructor(
    private positionservice: PositionService,
    private journeyService: JourneyService,
    private weatherService: WeahterService,
    private route: ActivatedRoute,
    private errorservice: ErrorService
  )
  {
    this.noSleep = new NoSleep();

    this.OBSERVER = {
      next: (p: IPosition) => {
        console.log('enter observer.next');
        this.lat = p.coords.latitude;
        this.lng = p.coords.longitude;
        this.accuracy = p.coords.accuracy;
        this.speed = p.coords.speed == null ? 0 : p.coords.speed;
        PositionComponent.currentPosition = p;
        this.positionservice.handleNextPositon(p, this.journey.journeyId, this.journey.positionUpdateInterval);
        this.weatherService.handleNextWeatherData(p, this.journey.journeyId, this.journey.weatherUpdateInterval);
        console.log('position updated successfully');
        console.log('leave observer.next');

        this.positionDistanceToLast = this.positionservice.distanceToLast;
        this.weatherDistanceToLast = this.weatherService.distanceToLast;
      }
    };
    PositionService.positionStream.subscribe(this.OBSERVER);
    this.errMsg = this.errorservice.clearErrorMessage();
  }

  ngOnInit(): void {

    const journeyId = this.route.snapshot.paramMap.get('journeyId');
    this.getJourney(parseInt(journeyId, 10));

    // intit with dummy values - Teotihuacan, Pyramid of the Moon, Bundesstaat Mexiko, Mexiko
    // url: https://www.google.de/maps/place/Pyramid+of+the+Moon,+Estado+de+M%C3%A9xico,+Mexiko/@19.6995767,-98.8446486,19z ->
    // -> /data=!3m1!4b1!4m5!3m4!1s0x85d1ea9bb7378655:0x2c89e183d7416a8c!8m2!3d19.6996098!4d-98.8439619
    this.lat = 19.6995767;
    this.lng = -98.8446486;
    this.accuracy = 20;
    this.speed = 0;
  }

  public getCurrentPosition(): void {
    this.positionservice.getCurrentPosition();
  }

  public watchPosition(): void {
    this.noSleep.enable();
    console.log('nosleep enabled');
    this.positionservice.watchPosition();
    this.watchId = this.positionservice.getWatchId();
  }

  public clearWatch(): void {
    this.noSleep.disable();
    console.log('nosleep disabled');
    this.positionservice.clearWatch();
    this.watchId = this.positionservice.getWatchId();
  }

  getJourney(jounreyId: number): void {
    this.journeyService.getJourney(jounreyId).subscribe({ next: j => { this.journey = j; }});
  }
}





