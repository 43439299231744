import { Component, Host, Input, OnInit } from '@angular/core';
import { Alert } from '../../models/alert';

@Component({
  selector: 'app-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss'],
  host: {}
})
export class ToastContainerComponent implements OnInit {

  @Input() alerts: Alert[];
  
  readonly alertClasses = {
    'alert-primary': 'bg-primary',
    'alert-secondary': 'bg-secondary',
    'alert-success': 'bg-success',
    'alert-danger': 'bg-danger',
    'alert-warning': 'bg-warning',
    'alert-info': 'bg-info',
    'alert-light': 'bg-light',
    'alert-dark': 'bg-dark'
  };
   
  constructor() { }

  ngOnInit(): void {
  }

  getAlertLevelClass(level: number): string {

    let alertClass;

    switch (level) {
      case 1:
          alertClass = this.alertClasses['alert-primary'];
          break;
      case 2:
          alertClass = this.alertClasses['alert-success'];
          break;
      case 3:
          alertClass = this.alertClasses['alert-warning'];
          break;
      case 4:
          alertClass = this.alertClasses['alert-danger'];
          break;
      case 5:
          alertClass = this.alertClasses['alert-secondary'];
          break;
      default:
          alertClass = this.alertClasses['alert-primary'];
          break;
    }
    return alertClass;
  }
}
