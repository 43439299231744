import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PauleoTravelGuest } from 'src/models/_classes';
import { GuestService } from '../services/guest.service';

@Component({
  selector: 'app-guest-configuration-panel',
  templateUrl: './guest-configuration-panel.component.html',
  styleUrls: ['./guest-configuration-panel.component.scss']
})
export class GuestConfigurationPanelComponent implements OnInit {

  guest: PauleoTravelGuest;
  guestExists: boolean;

  public guestData: FormGroup

  public readonly guestNameDefault = 'Beispiel-toller-Gast';
  public readonly guestEMailDefault = 'beispiel@beispiel.com';
  public readonly guestInfoDefault = 'Infos über den Gast';

  constructor(
    private guestService: GuestService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) 
  {
    this.createForm();
  }

  ngOnInit(): void {

    this.guestExists = false;

    const guestId = parseInt(this.route.snapshot.paramMap.get('id'), 10) || 0;
    if (guestId > 0) {
      this.getGuest(guestId);
      return;
    }
    if (!this.guestData) { this.createForm(); }
  }

  onSubmitCreatedOrUpdated(): void {

    if (this.guestData.invalid) { return; }

    if (this.guest == null) {
      this.onSubmitCreated();
    }
    else {
      this.onSubmitUpdated();
    }
  }

  onSubmitCreated(): void {

    const newGuest: PauleoTravelGuest = new PauleoTravelGuest();

    newGuest.userName = this.gd.guestName?.value;
    newGuest.email = this.gd.guestEMail?.value;
    newGuest.info = this.gd.guestInfo?.value;

    this.guestService.addGuest(newGuest).subscribe(
      {
        next: g => {
          this.guest = g;
          this.router.navigate(['/GuestOverview']);
        }
      }
    );
  }

  onSubmitUpdated(): void {

    const guest: PauleoTravelGuest = new PauleoTravelGuest();

    guest.id = this.guest.id;
    guest.userName = this.gd.guestName?.value;
    guest.email = this.gd.guestEMail?.value;
    guest.info = this.gd.guestInfo?.value;

    this.guestService.updateGuest(this.guest.id, guest).subscribe(
      {
        next: g => {
          this.guest = g;
          this.router.navigate(['/GuestOverview']);
        }
      }
    );
  }

  getGuest(guestId: number): void {

    this.guestService.getGuest(guestId).subscribe(
      {
        next: g => {
          this.guest = g;
          if (!this.guestData) { this.createForm(); }
          this.initForm(g);
        },
        complete: () => { console.log('getguest: complete'); }
      }
    );
  }

  createForm(): void {

    this.guestData = this.formBuilder.group({
      guestName: ['', [Validators.required, Validators.minLength(4)]],
      guestEMail: ['', [Validators.required, Validators.email]],
      guestInfo: ''
    });
  }

  initForm(guest: PauleoTravelGuest): void {
    this.gd.guestName.setValue(guest.userName);
    this.gd.guestEMail.setValue(guest.email);
    this.gd.guestInfo.setValue(guest.info);
  }

  get gd(): { [key: string]: AbstractControl } { return this.guestData.controls; }
}
