import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
   } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Alert } from 'src/alert/models/alert';
import { AlertService } from 'src/alert/services/alert.service';

export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private alertService: AlertService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
        .pipe(
            retry(1),
            catchError((error: HttpErrorResponse) => {
            let errorMessage = '';
            if (error.error instanceof ErrorEvent) {
                // client-side error
                errorMessage = `Error: ${error.error.message}`;
            } else {
                // server-side error
                errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
            // window.alert(errorMessage);
            const httpErrorAlert: Alert = new Alert(3, 'W A R N U N G !', errorMessage, true, 3000);
            this.alertService.alert(httpErrorAlert);
            return throwError(errorMessage);
            })
        );
    }
}
