import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthorizeService } from 'src/api-authorization/authorize.service';

@Component({
  selector: 'app-nav-menu-top',
  templateUrl: './nav-menu-top.component.html',
  styleUrls: ['./nav-menu-top.component.scss']
})
export class NavMenuTopComponent implements OnInit {

  constructor(private authorizeService: AuthorizeService) { }

  public isAuthenticated: Observable<boolean>;
  public isMenuCollapsed = true;

  ngOnInit(): void {
    this.isAuthenticated = this.authorizeService.isAuthenticated();
  }

}
