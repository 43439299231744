import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PositionComponent } from './position/position.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AdminComponent } from './admin/admin.component';
import { NavMenuBottomComponent } from './nav-menu-bottom/nav-menu-bottom.component';
import { IndexPageComponent } from './index-page/index-page.component';
import { MapComponent } from './map/map.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
import { AuthorizeInterceptor } from 'src/api/Interceptors/Authorize/authorize.interceptor';
import { HttpErrorInterceptor } from '../api/Interceptors/Error/http-error.interceptor';
import { JourneycreatorComponent } from './journeycreator/journeycreator.component';
import { JourneymanagerComponent } from './journeymanager/journeymanager.component';
import { JourneyControlPanelComponent } from './journey-control-panel/journey-control-panel.component';
import { JourneyOverviewComponent } from './journey-overview/journey-overview.component';
import { PLDatePickerAdapter } from 'src/utilities/datepicker-adapter';
import { JourneyConfigurationPanelComponent } from './journey-configuration-panel/journey-configuration-panel.component';
import { GuestcreatorComponent } from './guestcreator/guestcreator.component';
import { GuestOverviewComponent } from './guest-overview/guest-overview.component';
import { GuestConfigurationPanelComponent } from './guest-configuration-panel/guest-configuration-panel.component';
import { JourneyAddGuestComponent } from './journey-add-guest/journey-add-guest.component';
import { NavMenuTopComponent } from './nav-menu-top/nav-menu-top.component';
import { RouterModule } from '@angular/router';
import { JourneyDashboardComponent } from './journey-dashboard/journey-dashboard.component';
import { NavMenuSideComponent } from './nav-menu-side/nav-menu-side.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JourneyAddGuestsComponent } from './journey-add-guests/journey-add-guests.component';
import { AlertModule } from 'src/alert/alert.module';
import { AlertService } from 'src/alert/services/alert.service';
import { MediaModule } from 'src/media/media.module';
import { SignalRModule } from 'src/signal-r/signal-r.module';
import { MapsModule } from 'src/maps/maps/maps.module';
import { SettingsComponent } from './settings/settings.component';

@NgModule({
  declarations: [
    AppComponent,
    PositionComponent,
    AdminComponent,
    NavMenuBottomComponent,
    IndexPageComponent,
    MapComponent,
    JourneycreatorComponent,
    JourneymanagerComponent,
    JourneyControlPanelComponent,
    JourneyOverviewComponent,
    JourneyConfigurationPanelComponent,
    GuestcreatorComponent,
    GuestOverviewComponent,
    GuestConfigurationPanelComponent,
    JourneyAddGuestComponent,
    NavMenuTopComponent,
    JourneyDashboardComponent,
    NavMenuSideComponent,
    JourneyAddGuestsComponent,
    SettingsComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ApiAuthorizationModule,
    MediaModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgbModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB_u-vGdit7I6CSiR11FdO9fvPs6gQBbko&region=DE&libraries=geometry'
    }),
    FormsModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    RouterModule,
    BrowserAnimationsModule,
    AlertModule,
    SignalRModule,
    MapsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true, deps: [AlertService] },
    { provide: PLDatePickerAdapter }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
