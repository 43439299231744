import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CameraComponent } from './components/camera/camera.component';



@NgModule({
  declarations: [
    CameraComponent
  ],
  imports: [
    CommonModule
  ]
})
export class MediaModule { }
