<div class="row">
    <div class="col-sm-12">
        <a class="nav-link d-flex flex-column" [routerLink]="['/JourneyCreator']">
            <i class="bi bi-plus-circle add-item"></i>
        </a>
    </div>
</div>
<div class="row">
    <div class="col-sm-12">
        <table *ngIf="journeys != null" class="table table-bordered">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Status</th>
                    <th scope="col">&Ouml;ffnen</th>
                    <th scope="col">Starten...</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let journey of journeys">
                    <td>{{ journey.name }}</td>
                    <td [class]="journeyStateUtilities.getClassAtrribute(journey.state)">{{ journey.state }}</td>
                    <td><button type="button" [routerLink]="['../control-panel', journey.journeyId]" routerLinkActive="router-link-active" class="btn btn-primary btn-lg">+</button></td>
                    <td>
                        <button type="button" [disabled]="!checkIfStarted(journey.state)" class="btn btn-success btn-lg" [routerLink]="['/Position', journey.journeyId]" outerLinkActive="router-link-active">Los...</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>