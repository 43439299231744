export class Alert {

    constructor(
        lvl: number,
        hd: string,
        msg: string,
        fd: boolean,
        fdA: number
    ) {
        this.level = lvl;
        this.heading = hd;
        this.message = msg;
        this.fade = fd;
        this.fadeAfter = fdA;
    }

    level: number;
    heading: string;
    message: string;
    fade: boolean;
    fadeAfter: number;
}
