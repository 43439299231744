import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-menu-side',
  templateUrl: './nav-menu-side.component.html',
  styleUrls: ['./nav-menu-side.component.scss']
})
export class NavMenuSideComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
