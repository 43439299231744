<div class="row">
    <div class="col-sm-12 col-md-6">
        <!--<button type="button" *ngIf="!bStreaming" class="btn btn-primary mr-3" (click)="playMediaStream()">Kamera aktivieren</button>
        <button type="button" *ngIf="bStreaming" class="btn btn-primary mr-3" (click)="stopMediaStream()">Kamera beenden</button>-->
        <button type="button" *ngIf="bStreaming" class="btn btn-primary mr-3" (click)="takePicture()">Ausl&ouml;sen</button>
        <button type="button" *ngIf="bStreaming" class="btn btn-primary mr-3" (click)="recordPicture()">Abschicken</button>
        <button *ngIf="bPictureInProcess" type="button" class="btn btn-warning" (click)="clearCanvas()">Abbrechen</button>
        <button *ngIf="!bPictureInProcess" type="button" class="btn btn-warning" (click)="locationBack()">Zurück</button>
    </div>
</div>
<div class="row mt-3">
    <div id="video-div" class="col-sm-12 col-md-6">
        <video #video style="width: 100%;"></video>
    </div>
    <div id="img-div" class="col-sm-12 col-md-6">
        <canvas #canvas style="width: 100%;">
            <img #img />
        </canvas>
    </div>
</div>