import { from, Observable } from 'rxjs';

export class UserMedia {

    private readonly mediaDevicesSupported: boolean;
    
    private mediaRecorder: MediaRecorder;
    private recordedChunks = [];

    constructor() {
        this.mediaDevicesSupported = 'mediaDevices' in navigator;
    }

    public enumerateDevices(): void {

        from(navigator.mediaDevices.enumerateDevices());
    }

    public getUserMedia(mediaStreamConstraints: MediaStreamConstraints): Observable<MediaStream> {

        if (!this.mediaDevicesSupported) { return; }

        return from(navigator.mediaDevices.getUserMedia( mediaStreamConstraints ));
    }

    public playMediaStream(videoElement: HTMLVideoElement, mediaStreamConstraints: MediaStreamConstraints): void {
                
        this.getUserMedia(mediaStreamConstraints).subscribe(
            (mediaStream) => {
                videoElement.srcObject = mediaStream;
                videoElement.play();
            }
        );
    }

    public startRecording(mediaStreamConstraints: MediaStreamConstraints): void {

        const options = { };

        this.getUserMedia(mediaStreamConstraints).subscribe(
            (mediaStream) => {
                this.mediaRecorder = new MediaRecorder(mediaStream, options);
                this.mediaRecorder.ondataavailable = this.recordMediaStream;
                this.mediaRecorder.start();
            }
        );
    }

    public stopRecording(): void {

        if (this.mediaRecorder?.state === 'recording') {
            this.mediaRecorder.stop();
        }
    }

    public recordMediaStream(blobEvent: BlobEvent): void {

        if (blobEvent.data.size > 0) {
            this.recordedChunks.push(blobEvent.data);
        }
    }
}
