<div class="row">
    <div class="col-sm-12">
        <!--<button *ngIf="journey == null" id="btn-new" type="button" class="btn btn-primary btn-lg" data-target="#form-createjourney" data-toggle="collapse">+ Neue Reise</button>-->
        <!--<br />-->
        <form *ngIf="journey == null" id="form-createjourney" [formGroup]="journeyData">
            <div class="form-group">
                <label for="jouneyname">Gib der Reise einen Namen</label>
                <input type="text" class="form-control" id="journeyname" formControlName="journeyName" placeholder="Tolle-Reise-Name" required>
                <div *ngIf="(jd.journeyName.touched || jd.journeyName.touched) && jd.journeyName.errors" class="alert alert-danger">
                    <div *ngIf="jd.journeyName.errors.required">
                        Bitte einen Namen eingeben.
                    </div>
                    <div *ngIf="jd.journeyName.errors.minlength">
                        Der Name muss mindestens 4 Zeichen lang sein.
                    </div>
                </div>
            </div>
            <div class="form-group mr-3">
                <h5 class="d-flex justify-content-between"><span>Daten permanent speichern</span></h5>
                <div class="form-check">
                    <input class="form-check-input" id="bPersistentStorage" type="checkbox" formControlName="journeyBPersistantStorage" checked>
                    <label class="form-check-label" for="bPersistentStorage">
                      ja / nein
                    </label>
                </div>
                <h5 class="d-flex justify-content-between mt-3">Position aktualisieren</h5>
                <div class="form-check">
                    <input class="form-check-input" id="bPositonUpdate" type="checkbox" formControlName="jounrneyBPositonUpdate" checked>
                    <label class="form-check-label" for="bPositonUpdate">
                        ja / nein
                    </label>
                </div>
                <h5 class="d-flex justify-content-between mt-3">Wetterdaten aktualisieren</h5>
                <div class="form-check">
                    <input class="form-check-input" id="bWeatherUpdate" type="checkbox" formControlName="journeyBWeatherUpdate" checked>
                    <label class="form-check-label" for="bWeatherUpdate">
                      ja / nein
                    </label>
                </div>
            </div>
            <button (click)="onSubmitCreated()" id="btn-createjourney" class="btn btn-primary btn-lg">Reise anlegen</button>
        </form>
    </div>
</div>