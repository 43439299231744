import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiPaths, ApiUrl } from 'src/api/api.constants';
import { Weather } from '../../models/_classes';
import { IPosition } from 'src/models/_interfaces';

@Injectable({
  providedIn: 'root'
})
export class WeahterService {

  private readonly WeatheryApiPath = ApiUrl + ApiPaths.Weather;

  private storedPositon: IPosition;
  public distanceToLast: number;

  private readonly HTTP_OPTIONS;

  constructor(
    private httpclient: HttpClient,
  ) {
    this.HTTP_OPTIONS = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      responseType: 'text'
    };
  }

  public handleNextWeatherData(nextPosition: IPosition, journeyID: number, targetDistanceToLast: number): void {

    if (this.storedPositon == null) {
      this.storedPositon = nextPosition;
      this.distanceToLast = 0;
      this.addWeatherData(nextPosition, journeyID);
    }
    else {
      if (this.calculateDistance(this.storedPositon, nextPosition) >= targetDistanceToLast) {
        this.storedPositon = nextPosition;
        this.addWeatherData(nextPosition, journeyID);
      }
    }
  }

  async addWeatherData(position: IPosition, journeyID: number): Promise<void> {
    const payload = this.preparePayload(position, journeyID);
    await this.httpclient.post<Weather>(this.WeatheryApiPath, payload, this.HTTP_OPTIONS)
      .subscribe();
  }

  public preparePayload(position: IPosition, journeyID: number): string {

    const payload = JSON.stringify({
      latitude: position?.coords?.latitude === null ? 0 : position?.coords?.latitude,
      longitude: position?.coords?.longitude === null ? 0 : position?.coords?.longitude,
      accuracy: position?.coords?.accuracy === null ? 0 : position?.coords?.accuracy,
      altitude: position?.coords?.altitude === null ? 0 : position?.coords?.altitude,
      speed: position?.coords?.speed === null ? 0 : position?.coords?.speed,
      altitudeAccuracy: position?.coords?.altitudeAccuracy === null ? 0 : position?.coords?.altitudeAccuracy,
      heading: position?.coords?.heading === null ? 0 : position?.coords?.heading,
      timestamp: position?.timestamp === null ? 0 : position?.timestamp,
      journeyId: journeyID,
      ownerId: 0
    });
    return payload;
  }

  public calculateDistance(currentPosition: IPosition, nextPosition: IPosition): number {

    let distance: number;

    if (currentPosition != null && nextPosition != null) {

      const currentLatLng: google.maps.LatLng =
        new google.maps.LatLng(currentPosition?.coords?.latitude, currentPosition?.coords?.longitude);

      const nextLatLng: google.maps.LatLng =
        new google.maps.LatLng(nextPosition?.coords?.latitude, nextPosition?.coords?.longitude);
        // new google.maps.LatLng(48.873011, 9.3949059); Winndenden for testing

      distance = google.maps.geometry.spherical.computeDistanceBetween(currentLatLng, nextLatLng);
    }
    this.distanceToLast = distance;
    return distance;
  }
}
