import { Content } from './_classes'
import { IUser } from 'src/api-authorization/authorize.service'

export enum JourneyState {
    Created = 10,
    Configured = 20,
    Started = 30,
    Hidden = 40,
    Stopped = 50,
    Finished = 60,
    Closed = 70
}

export interface IJourney {
    journeyId: number;
    name: string;
    owner: IUser;
    created: Date | string;
    start: string;
    finish: string;
    dateBegin: Date | string;
    description: string;
    journeyTravelers: IJourneyTraveler[];
    journeyGuests: IJourneyGuest[];
    locations: Location[];
    state: any; // should be of type JourneyState but for extended flexibility any
    isActive: boolean;
    bPersistentStorage: boolean;
    bPositonUpdate: boolean;
    bWeatherUpdate: boolean;
    positionUpdateInterval: number;
    weatherUpdateInterval: number;
    invitationSent: Date | string;
    journeyGuid: string;
}

export interface IContent {
    id: number;
    journey: IJourney;
    created: Date | string;
    owner: IUser;
}


export interface IWeather {

    description: string;
    temperature: number;
    icon: string;
    weatherId: number;
    pos: IPositionFlat;
    name: string;
    journeyId: number;
    ownerId: number;
}

export interface IMessage extends Content {
    body: string;
}

export interface IMedia {
    fileName: string;
    fileType: string;
    dataUrlPrefix: string
    data: any;
    journeyId: number;
    ownerId: number;
}

export interface IJourneyTraveler {
    TravelerId: number;
    JourneyId: number;
    Traveler: IUser;
    Journey: IJourney;
}

export interface IJourneyGuest {
    guestId: number;
    journeyId: number;
    guest: IUser;
    journey: IJourney;
}

/*export interface IPauleoTravelUser {
    JourneyTravelers: IJourneyTraveler[];
    JourneyGuests: IJourneyGuest[];
    Journeys: IJourney[];
    Hosts: IHostToGuest[];
    Guests: IHostToGuest[];
    IsGuest: boolean;
}*/

export interface IHostToGuest {
    KnownAs: string;
    Info: string;
    GuestId: number;
    HostId: number;
    Guest: IUser;
    Host: IUser;
}

export interface IWeatherData {
    description: string;
    temperature: number;
    icon: string;
    weatherId: number;
    pos: IPositionFlat;
    name: string;
    journeyId: number;
    ownerId: number;
}

export interface IPositionFlat {
    // flat copy of geolocation js api - position
    accuracy: number;
    altitude: number;
    altitudeAccuracy: number;
    heading: number;
    latitude: number;
    longitude: number;
    speed: number;
    timestamp: number;

    // pl-relatet stuff
    journeyId: number;
    ownerId: number;
}

export interface IPosition {
    readonly coords: ICoordinates;
    readonly timestamp: number;
}
  
/** The position and altitude of the device on Earth, as well as the accuracy with which these properties are calculated. */
export interface ICoordinates {
    readonly accuracy: number;
    readonly altitude: number | null;
    readonly altitudeAccuracy: number | null;
    readonly heading: number | null;
    readonly latitude: number;
    readonly longitude: number;
    readonly speed: number | null;
}

export interface IDeviceOrientation {
    alpha: number;
    beta: number;
    gamma: number;
    absolute: boolean
  }


