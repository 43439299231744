
import { Component, OnInit, Input } from '@angular/core';
import { PositionService } from '../services/position.service';
import { PositionComponent } from '../position/position.component';
import { MapService } from '../services/map.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  @Input() geo: PositionComponent;
  @Input() lat: number;
  @Input() lng: number;

  public zoomEnabled: boolean;
  public zoom: number;

  constructor(
    private geolocationservice: PositionService,
    private mapservice: MapService
  ) {
    this.lat = 0.0;
    this.lng = 0.0;
    this.zoom = 17;
    this.zoomEnabled = false;
  }

  public ngOnInit(): void {}
}
