import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthorizeGuard } from 'src/api-authorization/authorize.guard';
import { PositionComponent } from './position/position.component';
import { GuestConfigurationPanelComponent } from './guest-configuration-panel/guest-configuration-panel.component';
import { GuestOverviewComponent } from './guest-overview/guest-overview.component';
import { GuestcreatorComponent } from './guestcreator/guestcreator.component';
import { IndexPageComponent } from './index-page/index-page.component';
import { JourneyConfigurationPanelComponent } from './journey-configuration-panel/journey-configuration-panel.component';
import { JourneyControlPanelComponent } from './journey-control-panel/journey-control-panel.component';
import { JourneyOverviewComponent } from './journey-overview/journey-overview.component';
import { JourneycreatorComponent } from './journeycreator/journeycreator.component';
import { JourneymanagerComponent } from './journeymanager/journeymanager.component';
import { JourneyDashboardComponent } from './journey-dashboard/journey-dashboard.component';
import { JourneyAddGuestsComponent } from './journey-add-guests/journey-add-guests.component';
import { CameraComponent } from 'src/media/components/camera/camera.component';
import { SettingsComponent } from './settings/settings.component';


const routes: Routes = [
  { path: '', component: IndexPageComponent},
  { path: 'Start', component: IndexPageComponent },
  { path: 'Position/:journeyId', component: PositionComponent, canActivate: [AuthorizeGuard] },
  { path: 'JourneyCreator', component: JourneycreatorComponent, canActivate: [AuthorizeGuard] },
  { path: 'Dashboard', component: JourneyDashboardComponent, canActivate: [AuthorizeGuard] },
  { path: 'Dashboard/:journeyId', component: JourneyDashboardComponent, canActivate: [AuthorizeGuard] },
  { path: 'Configuration/:journeyId', component: JourneyConfigurationPanelComponent},
  {
    path: 'JourneyManager',
    component: JourneymanagerComponent,
    canActivate: [AuthorizeGuard],
    children: [
      { path: '', redirectTo: 'overview', pathMatch: 'full' },
      { path: 'overview', component: JourneyOverviewComponent },
      { path: 'control-panel/:journeyId', component: JourneyControlPanelComponent }
    ]
  },
  { path: 'JourneyAddguest/:journeyId', component: JourneyAddGuestsComponent, canActivate: [AuthorizeGuard] },
  { path: 'GuestCreator', component: GuestcreatorComponent, canActivate: [AuthorizeGuard] },
  { path: 'GuestOverview', component: GuestOverviewComponent, canActivate: [AuthorizeGuard] },
  { path: 'GuestConfiguration', component: GuestConfigurationPanelComponent, canActivate: [AuthorizeGuard] },
  { path: 'GuestConfiguration/:id', component: GuestConfigurationPanelComponent, canActivate: [AuthorizeGuard] },
  { path: 'Camera/:journeyId', component: CameraComponent, canActivate: [AuthorizeGuard] },
  { path: 'Settings', component: SettingsComponent, canActivate: [AuthorizeGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
