<nav class="navbar navbar-expand navbar-light bg-light fixed-bottom justify-content-center p-0">
    <div class="container">
        <ul class="navbar-nav nav-justified w-100 text-center">
            <li class="nav-item" class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
                <a class="nav-link d-flex flex-column" (click)="isMenuCollapsed = true" [routerLink]="['/']">
                    <i class="bi bi-house-door-fill bi-navbottom"></i>
                    <span class="d-none d-sm-inline bi-txt">START</span>
                </a>
            </li>
            <li class="nav-item" *ngIf="(isAuthenticated | async)" [routerLinkActive]="['link-active']">
                <a class="nav-link d-flex flex-column" (click)="isMenuCollapsed = true" [routerLink]="['/JourneyManager']">
                    <i class="bi bi-signpost-2-fill bi-navbottom"></i>
                    <span class="d-none d-sm-inline bi-txt">REISEN</span>
                </a>
            </li>
            <!--<li class="nav-item" *ngIf="(isAuthenticated | async)" [routerLinkActive]="['link-active']">
                <a class="nav-link d-flex flex-column" (click)="isMenuCollapsed = true" [routerLink]="['/GuestOverview']">
                    <i class="bi bi-people-fill bi-navbottom"></i>
                    <span class="d-none d-sm-inline bi-txt">FREUNDE</span>
                </a>
            </li>-->
            <!--<li class="nav-item" *ngIf="(isAuthenticated | async)" [routerLinkActive]="['link-active']">
                <a class="nav-link d-flex flex-column" (click)="isMenuCollapsed = true" [routerLink]="['/JourneyCreator']">
                    <i class="bi bi-signpost-fill bi-navbottom"></i>
                    <span class="d-none d-sm-inline bi-txt">NEUE REISE</span>
                </a>
            </li>-->
            <li class="nav-item" *ngIf="(isAuthenticated | async)" [routerLinkActive]="['link-active']">
                <a class="nav-link d-flex flex-column" (click)="isMenuCollapsed = true" [routerLink]="['/Dashboard']">
                    <i class="bi bi-speedometer bi-navbottom"></i>
                    <span class="d-none d-sm-inline bi-txt">DASHBOARD</span>
                </a>
            </li>
            <li class="nav-item" *ngIf="(isAuthenticated | async)" [routerLinkActive]="['link-active']">
                <a class="nav-link d-flex flex-column" (click)="isMenuCollapsed = true" [routerLink]="['/Settings']">
                    <i class="bi bi-gear-fill bi-navbottom"></i>
                    <span class="d-none d-sm-inline bi-txt">SETTINGS</span>
                </a>
            </li>
            <!--<li class="nav-item" *ngIf="(isAuthenticated | async)" [routerLinkActive]="['link-active']">
                <a class="nav-link d-flex flex-column" (click)="isMenuCollapsed = true" [routerLink]="['/GuestCreator']">
                    <i class="bi bi-person-plus-fill"></i>
                    <span class="d-none d-sm-inline bi-txt">NEUER FREUND</span>
                </a>
            </li>-->
        </ul>
    </div>
</nav>