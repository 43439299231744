<div class="row">
    <div class="col-sm-12">
        <h3 *ngIf="journey != null">
            {{ journey.name }}
        </h3>

        <form *ngIf="journey != null" id="form-configure-journey" method="post" [formGroup]="journeyData" novalidate>
            <div class="form-row form-group">
                <div class="col-md-12 col-sm-12">
                    <label for="description">Beschreibung</label>
                    <textarea class="form-control" rows="3" formControlName="journeyDescription" [placeholder]="journeyDescriptionDefault" required></textarea>
                    <div *ngIf="(jd.journeyDescription.touched || jd.journeyDescription.touched) && jd.journeyDescription.errors" class="alert alert-danger">
                        <div *ngIf="jd.journeyDescription.errors.required">
                            Bitte eine Beschreibung eingeben.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row form-group">
                <div class="col-md-6 col-sm-12">
                    <label for="start">Sart</label>
                    <input type="text" class="form-control" formControlName="journeyStart" [placeholder]="journeyStartDefault" required>
                    <div *ngIf="(jd.journeyStart.touched || jd.journeyStart.touched) && jd.journeyStart.errors" class="alert alert-danger">
                        <div *ngIf="jd.journeyStart.errors.required">
                            Bitte einen Startpunkt eingeben.
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <label for="finish">Ziel</label>
                    <input type="text" class="form-control" formControlName="journeyFinish" [placeholder]="journeyFinishDefault" required>
                    <div *ngIf="(jd.journeyFinish.touched || jd.journeyFinish.touched) && jd.journeyFinish.errors" class="alert alert-danger">
                        <div *ngIf="jd.journeyFinish.errors.required">
                            Bitte einen Zielort eingeben.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row form-group">
                <div class="col-md-6 col-sm-12">
                    <label for="dateBegin">Startzeitpunkt</label>
                    <div class="input-group">
                        <ngb-datepicker id="dateBegin" formControlName="journeyDateBegin" [(ngModel)]="dateBegin" name="dateBegin" #d></ngb-datepicker>
                    </div>
                </div>
                <div class="col-md-3 col-sm-12">
                    <div class="form-group mr-3">
                        <h5 class="d-flex justify-content-between"><span>Daten permanent speichern</span></h5>
                        <div class="form-check">
                            <input class="form-check-input" id="bPersistentStorage"type="checkbox" formControlName="journeyBPersistantStorage">
                            <label class="form-check-label" for="bPersistentStorage">
                              ja / nein
                            </label>
                        </div>
                        <h5 class="d-flex justify-content-between mt-3">Position aktualisieren</h5>
                        <div class="form-check">
                            <input class="form-check-input" id="bPositonUpdate" type="checkbox" formControlName="jounrneyBPositonUpdate">
                            <label class="form-check-label" for="bPositonUpdate">
                                ja / nein
                            </label>
                        </div>
                        <label class="mt-1" for="positionUpdateAfter">Aktualisierung nach [m]</label>
                        <select class="form-control" formControlName="journeyPositionUpdateInterval">
                            <option *ngFor="let option of PositionUpdateIntervalOptions" [ngValue]="option">{{ option }}</option>
                        </select>
                        <h5 class="d-flex justify-content-between mt-3">Wetterdaten aktualisieren</h5>
                        <div class="form-check">
                            <input class="form-check-input" id="bWeatherUpdate" type="checkbox" formControlName="journeyBWeatherUpdate">
                            <label class="form-check-label" for="bWeatherUpdate">
                              ja / nein
                            </label>
                        </div>
                        <label class="mt-1" for="weatherUpdateAfter">Aktualisierung nach [m]</label>
                        <select class="form-control" formControlName="journeyWeatherUpdateInverval">
                            <option *ngFor="let option of WeatherUpdateIntervalOptions" [ngValue]="option">{{ option }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3 col-sm-12">
                    <table class="table table-bordered">
                        <tbody>
                            <tr>
                                <td [ngClass]="journeyClasses">
                                    <h4>Status</h4>
                                    <p>
                                        {{ journeyStateFriendly }}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="form-row">
                <div class="btn-toolbar" role="toolbar" aria-label="toolbar with submit and cancel button">
                    <div class="btn-group mr-3" role="group" aria-label="first group">
                        <button type="submit" id="configureJourney" (click)="onSubmitUpdated()" class="btn btn-success btn-lg">Speichern</button>
                    </div>
                    <div class="btn-group mr-3" role="group" aria-label="second group">
                        <button type="cancel" id="configureJourneyCancel" class="btn btn-warning btn-lg" [routerLink]="['/JourneyManager/control-panel', journey.journeyId]" routerLinkActive="router-link-active">Abbrechen</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>