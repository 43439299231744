import { Component, Input, OnInit } from '@angular/core';
import { PositionComponent } from 'src/app/position/position.component';
import { MapService } from 'src/app/services/map.service';
import { PositionService } from 'src/app/services/position.service';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class MapsComponent implements OnInit {

  @Input() geo: PositionComponent;
  @Input() lat: number;
  @Input() lng: number;

  public zoomEnabled: boolean;
  public zoom: number;

  constructor(
    private geolocationservice: PositionService,
    private mapservice: MapService
  ) {
    this.lat = 0.0;
    this.lng = 0.0;
    this.zoom = 17;
    this.zoomEnabled = false;
  }

  ngOnInit(): void {
  }

}
