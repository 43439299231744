import { Injectable } from '@angular/core';
import { ErrorService } from './error.service';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiPaths, ApiUrl } from 'src/api/api.constants';
import { IPosition } from 'src/models/_interfaces';

@Injectable({
  providedIn: 'root'
})
export class PositionService {

  public static currentPosition: IPosition;
  public static positionStream: Subject<IPosition>;

  private readonly PositionApiPath = ApiUrl + ApiPaths.Position;

  constructor(
    private errorservice: ErrorService,
    private httpclient: HttpClient
  ) {
      this.HTTP_OPTIONS = {
        headers: new HttpHeaders({'Content-Type': 'application/json'}),
        responseType: 'text'
      };

      this.GEO_POS_OPTIONS = {
        enableHighAccuracy: true,
        maximumAge        : 30000,
        timeout           : 27000
      };

      PositionService.positionStream = new Subject<IPosition>();
      this.errMsg = this.errorservice.clearErrorMessage();
  }

  private storedPositon: IPosition;
  public distanceToLast: number;

  private readonly HTTP_OPTIONS;
  private readonly GEO_POS_OPTIONS;

  private watchId: number;
  public errMsg: string;

  public handleNextPositon(nextPosition: IPosition, journeyID: number, targetDistanceToLast: number): void {

    if (this.storedPositon == null) {
      this.storedPositon = nextPosition;
      this.distanceToLast = 0;
      this.http_post(nextPosition, journeyID);
    }
    else {
      if (this.calculateDistance(this.storedPositon, nextPosition) >= targetDistanceToLast) {
        this.storedPositon = nextPosition;
        this.http_post(nextPosition, journeyID);
      }
    }
  }

  public async http_post(position: IPosition, journeyID: number): Promise<void> {
    try {
      console.log('geo-location.service.http_post enter');

      const payload = this.preparePayload(position, journeyID);

      const response = await this.httpclient.post<PosFlat>(this.PositionApiPath, payload, this.HTTP_OPTIONS)
        .subscribe(
          (p) => console.log('next called: for post<Position> at: ' + position.timestamp),
        );
      console.log(response);
      console.log('geo-location.service.http_post leave');
    }
    catch (Error) {
      this.errorservice.handleError(Error);
    }
  }

  public getCurrentPosition(): void {

    try {
      navigator.geolocation.getCurrentPosition(this.geo_success, this.geo_err, this.GEO_POS_OPTIONS);
    }
    catch (Error)
    {
      this.errMsg = 'Fehler bei der Bestimmung der Position';
      this.errorservice.handleError(Error);
    }
  }

  public watchPosition(): void {

    try {
      this.watchId = navigator.geolocation.watchPosition(this.geo_success, this.geo_err, this.GEO_POS_OPTIONS);
    }
    catch (Error) {
      this.errorservice.handleError(Error);
    }
  }

  public clearWatch(): void {

    try {
      console.log('clearWatch enter');
      navigator.geolocation.clearWatch(this.watchId);
      console.log(this.watchId);
      this.watchId = -1;
      console.log(this.watchId);
      console.log('clearWatch before leave');
    }
    catch (Error) {
      this.errorservice.handleError(Error);
    }
  }

  private geo_err(): void {
    console.log('Fehler');
  }

  private geo_success(position: IPosition): void {
    console.log('geo_success called');
    PositionService.positionStream.next(position);
    console.log('Position updated. Time: ' + position.timestamp);
    console.log('geo_succes before leave');
  }

  public preparePayload(position: IPosition, journeyID: number): string {

    const payload = JSON.stringify({
      latitude: position?.coords?.latitude === null ? 0 : position?.coords?.latitude,
      longitude: position?.coords?.longitude === null ? 0 : position?.coords?.longitude,
      accuracy: position?.coords?.accuracy === null ? 0 : position?.coords?.accuracy,
      altitude: position?.coords?.altitude === null ? 0 : position?.coords?.altitude,
      speed: position?.coords?.speed === null ? 0 : position?.coords?.speed,
      altitudeAccuracy: position?.coords?.altitudeAccuracy === null ? 0 : position?.coords?.altitudeAccuracy,
      heading: position?.coords?.heading === null ? 0 : position?.coords?.heading,
      timestamp: position?.timestamp === null ? 0 : position?.timestamp,
      journeyId: journeyID,
      ownerId: 0
    });
    return payload;
  }

  public calculateDistance(currentPosition: IPosition, nextPosition: IPosition): number {

    let distance: number;

    if (currentPosition != null && nextPosition != null) {

      const currentLatLng: google.maps.LatLng =
        new google.maps.LatLng(currentPosition?.coords?.latitude, currentPosition?.coords?.longitude);

      const nextLatLng: google.maps.LatLng =
        new google.maps.LatLng(nextPosition?.coords?.latitude, nextPosition?.coords?.longitude);
        // new google.maps.LatLng(48.873011, 9.3949059); Winndenden for testing

      distance = google.maps.geometry.spherical.computeDistanceBetween(currentLatLng, nextLatLng);
    }
    this.distanceToLast = distance;
    return distance;
  }

  public getWatchId(): number {
    return this.watchId > 0 ? this.watchId : -1;
  }
}

// flat version of interface Position - less trouble during transfer to api
export interface PosFlat {
  readonly accuracy: number;
  readonly altitude: number | null;
  readonly altitudeAccuracy: number | null;
  readonly heading: number | null;
  readonly latitude: number;
  readonly longitude: number;
  readonly speed: number | null;
  readonly timestamp: number;
  readonly journeyId: number | null;
}