import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModelsModule } from 'src/models/models.module';
import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
import { MapsModule } from 'src/maps/maps/maps.module';

import { PositionSrComponent } from './components/position-sr/position-sr.component';
import { MediaSrComponent } from './components/media-sr/media-sr.component';
import { WeatherSrComponent } from './components/weather-sr/weather-sr.component';

@NgModule({
  declarations: [
    PositionSrComponent,
    MediaSrComponent,
    WeatherSrComponent
  ],
  imports: [
    CommonModule,
    ModelsModule,
    ApiAuthorizationModule,
    MapsModule
  ],
  exports: [
    PositionSrComponent,
    MediaSrComponent,
    WeatherSrComponent
  ]
})
export class SignalRModule { }
