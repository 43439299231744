import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Alert } from '../models/alert';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private readonly subject: Subject<Alert>;
  private readonly $alerts: Subject<Array<Alert>>;
  alerts: Alert[];

  constructor() {
    this.subject = new Subject<Alert>();
    this.$alerts = new Subject<Array<Alert>>();
    this.alerts = new Array<Alert>();
  }

  onAlert(): Observable<Array<Alert>> {
    return this.$alerts.asObservable();
  }

  alert(alert: Alert): void {
    this.alerts.push(alert);
    this.subject.next(alert);
    this.$alerts.next(this.alerts);

    if (alert.fade) {
      setTimeout(() => this.cleanUp(alert), alert.fadeAfter);
    }

  }

  cleanUp(alert: Alert): void {
    if (!this.alerts.includes(alert)) { return; }
    this.alerts = this.alerts.filter((a) => a !== alert);
    this.$alerts.next(this.alerts);
  }
}
