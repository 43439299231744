import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { Journey } from 'src/models/_classes';
import { JourneyService } from '../services/journey.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './journey-dashboard.component.html',
  styleUrls: ['./journey-dashboard.component.scss']
})
export class JourneyDashboardComponent implements OnInit {

  journey: Journey;
  journeySelectedName: string;
  journeys: Journey[];
  bJourneyParamSuccess: boolean;

  constructor(
    private journeyService: JourneyService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) { 
    this.journeys = [];
  }

  ngOnInit(): void {
    
    this.bJourneyParamSuccess = false;

    const journeyIdParam = this.route.snapshot.paramMap.get('journeyId');    
    const journeyId: number = parseInt(journeyIdParam, 10) || 0;
    if (journeyId > 0) {
      this.getJourney(journeyId);
    }
    else {
      this.getJounreys();
    }
  }

  locationBack(): void {
    this.location.back();
  }

  getJourney(jounreyId: number): void {
    this.journeyService.getJourney(jounreyId).subscribe(
      {
        next: j => { this.journey = j; },
        error: () => { this.bJourneyParamSuccess = false; },
        complete: () => { this.bJourneyParamSuccess = true; }
      }
    );
  }

  getJounreys(): void {
    this.journeyService.getJourneys().subscribe(
      {
        next: (j) => { 
          this.journeys = j;
        }
      }
    );
  }

  onJourneySelected(value: any): void {
    
    const jounreyId: number = parseInt(value, 10) || 0;

    if (jounreyId > 0) {
      this.router.navigate(['Dashboard', value]);
    }
  }
}
