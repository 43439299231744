export const AppName = 'pauleoTravelApp';

export const ApiUrl = 'https://piraten-sind-wir.de'; // 'https://localhost:44347'; // 'https://travel.pauleo.de'; //

const apiEndpoints: ApiPathsType = {

    Journey: `/api/Journey`,
    Position: `/api/Position`,
    Chat: `/api/Chat`,
    Guest: `/api/Guest`,
    HostToGuest: `/api/HostToGuest`,
    Message: `/api/Message`,
    Weather: `/api/Weather`,
    Media: `/api/Media`
  };

interface ApiPathsType {

    readonly Journey: string;
    readonly Position: string;
    readonly Chat: string;
    readonly Guest: string;
    readonly HostToGuest: string;
    readonly Message: string;
    readonly Weather: string;
    readonly Media: string;
  }

export const ApiPaths: ApiPathsType = apiEndpoints;
