<div class="row">
    <div class="col-sm-12">
        <h3 *ngIf="journey != null">
            {{ journey.name }}
        </h3>

        <table *ngIf="journey != null" class="table table-bordered">
            <tbody>
                <tr>
                    <td colspan="2">
                        <h5>Beschreibung</h5>
                        <p>
                            {{ journey.description }}
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h5>Start</h5>
                        <p>
                            {{ journey.start }}
                        </p>
                    </td>
                    <td>
                        <h5>Ziel</h5>
                        <p>
                            {{ journey.finish }}
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h5>Startzeitpunkt</h5>
                        <p>
                            {{ journey.dateBegin.toString().substring(0, 19).replace('T', ' ') }}
                        </p>
                    </td>
                    <td [ngClass]="journeyClasses">
                        <h5>Status</h5>
                        <p>
                            {{ journeyStateFriendly }}
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div *ngIf="journeyStateFriendly === 'gestartet'" class="btn-group mr-3" role="group" aria-label="second group">
                            <button type="button" class="btn btn-success btn-lg" (click)="go(journey.journeyId)">Los...</button>
                        </div>
                    </td>
                    <td>
                        <label for="selectState">Status &auml;ndern</label>
                        <select class="form-control" [(ngModel)]="journeyStateFriendly" (change)="updateJourney(journey.journeyId, journey)">
                            <option>{{ journeyStateUtilities.CONFIGURED }}</option>
                            <option>{{ journeyStateUtilities.STARTED }}</option>
                            <option>{{ journeyStateUtilities.HIDDEN }}</option>
                            <option>{{ journeyStateUtilities.STOPPED }}</option>
                            <option>{{ journeyStateUtilities.FINISHED }}</option>
                            <option>{{ journeyStateUtilities.CLOSED }}</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>
                        <!--<h5 class="d-flex justify-content-between">Teilen</h5>-->
                        <div class="btn-group mr-3" role="group" aria-label="second group">
                            <button *ngIf="bInvitationPrepared" type="button" (click)="inviteGuests()" class="btn btn-success btn-lg mb-1">Einladung versenden</button>
                            <button *ngIf="!bInvitationPrepared" type="button" (click)="stageInvitation()" class="btn btn-primary btn-lg mb-1">Einladen</button>
                        </div>
                        <div class="btn-group mr-3" role="group" aria-label="first group">
                            <button type="button" class="btn btn-primary btn-lg mb-1" [routerLink]="['/JourneyAddguest', journey.journeyId]" outerLinkActive="router-link-active">G&auml;ste hinzuf&uuml;gen</button>
                        </div>
                        <div class="btn-group mr-3" role="group" aria-label="first group">
                            <button type="button" class="btn btn-primary btn-lg mb-1" [routerLink]="['/Configuration', journey.journeyId]" outerLinkActive="router-link-active">Bearbeiten</button>
                        </div>
                    </td>
                    <td *ngIf="journey.invitationSent">
                        <h5>Einladungen verschickt</h5>
                        {{ journey.invitationSent.toString().substring(0, 19).replace('T', ' ') }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <table *ngIf="guests != null" class="table table-bordered">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">E-Mail</th>
                    <th scope="col">&Ouml;ffnen</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let guest of guests">
                    <td>{{ guest.userName }}</td>
                    <td>{{ guest.email }}</td>
                    <td><button type="button" class="btn btn-primary btn-lg" [routerLink]="['/GuestConfiguration', guest.id]" routerLinkActive="router-link-active">+</button></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>