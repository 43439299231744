import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPaths, ApiUrl } from 'src/api/api.constants';
import { Journey } from '../../models/_classes';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JourneyService {

  private readonly JourneyApiPath = ApiUrl + ApiPaths.Journey;
  private readonly subscription;

  private JOURNEYS: Journey[];
  public journeysUpdateRequired: boolean;
  
  constructor(
    private httpclient: HttpClient
  ) 
  { 
    this.journeysUpdateRequired = true;
    this.subscription = this.getJourneys().subscribe(
      {
        next: (j) => { this.JOURNEYS = j; },
        complete: () => { this.journeysUpdateRequired = false; },
    });
  }
  
  /**
   * @returns Observable
   * @summary Gets all journeys realated to user
   */
  getJourneys(): Observable<Journey[]> {
    return this.httpclient.get<Journey[]>(this.JourneyApiPath);
  }

  /**
   * @param  {number} id
   * @returns Observable
   * @summary Gets a specific journey realated to user
   */
  getJourney(id: number): Observable<Journey> {
    return this.httpclient.get<Journey>(this.JourneyApiPath + '/' + id);
  }

  /**
   * @param  {Journey} journey
   * @returns Observable
   * @summary Adds a new journey
   */
  addJourney(journey: Journey): Observable<Journey> {
    return this.httpclient.post<Journey>(this.JourneyApiPath, journey);
  }

  /**
   * @param  {number} id
   * @param  {Journey} journey
   * @returns Observable
   * @summary Updates a journey related to user
   */
  updateJourney(id: number, journey: Journey): Observable<Journey> {
    return this.httpclient.put<Journey>(this.JourneyApiPath + '/' + id, journey);
  }
  
  /**
   * @param  {string} id
   * @returns Observable
   * @summary Deletes specific journey
   */
  deleteJourney(id: string): Observable<Journey> {
    return this.httpclient.delete<Journey>(this.JourneyApiPath + 'journey/' + id);
  }
}
