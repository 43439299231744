import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { Subscription } from 'rxjs';
import { AlertService } from '../../services/alert.service';
import { Alert } from '../../models/alert';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        height: '70px',
        opacity: 1,
      })),
      state('closed', style({
        height: '0px',
        opacity: 0,
      })),
      transition('closed => open', [
        animate('0.7s')
      ]),
      transition('open => closed', [
        animate('0.7s')
      ]),

    ])
  ]
})
export class AlertComponent implements OnInit, OnDestroy {

  state: string;

  alertMessage: string;
  alertSubscription: Subscription;

  alerts: Alert[];

  constructor(
    private alertService: AlertService
  ) {
    this.alerts = new Array<Alert>();
  }

  ngOnInit(): void {
    this.alertSubscription = this.alertService.onAlert()
      .subscribe(
        (alerts) => {
          this.initAlerts(alerts);
        }
      );
  }

  initAlerts(alerts: Array<Alert>): void {
    this.alerts = alerts;

    alerts.forEach((a) => {
      if (!this.alerts.includes(a)) {
        
      }
    });


    /*if (alert.fade) {
      setTimeout(() => { this.alerts.splice(0, this.alerts.length) }, alert.fadeAfter);
    }*/
  }

  clearAlert(): void {
  }

  ngOnDestroy(): void {
    this.alertSubscription.unsubscribe();
  }
}
