import * as signalR from "@microsoft/signalr";
import { AuthorizeService } from "src/api-authorization/authorize.service";


export class SignalR {

    private connection: signalR.HubConnection;
    private authorizeService: AuthorizeService
    private authToken: string;

    private authObserver = {
        next: (at: string) => this.authToken = at,
        complete: () => this.getConnection()
    }
    
    constructor(
        private url: string,
    ) {
        
        this.authorizeService = new AuthorizeService();
        this.authorizeService.getAccessToken().subscribe(
            this.authObserver
        );
    }

    getConnection(): signalR.HubConnection {

        if (this.connection != null) {
            return this.connection
        }
        else {
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(this.url, { accessTokenFactory: () => this.authToken})
                .withAutomaticReconnect()
                .build();

            this.connection.start().catch(err => document.write(err));

            return this.connection;
        }
    }
}
