import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapsComponent } from './maps.component';
import { AgmCoreModule } from '@agm/core';



@NgModule({
  declarations: [
    MapsComponent
  ],
  imports: [
    CommonModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB_u-vGdit7I6CSiR11FdO9fvPs6gQBbko&region=DE&libraries=geometry'
    })
  ],
  exports: [
    MapsComponent
  ]
})
export class MapsModule { }
